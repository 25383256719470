import React, { useState } from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const VisitHospital = () => {

    const images = [
        'img/content/visithospital-19.png',
        'img/content/visithospital-20.png',
        'img/content/visithospital-21.png',
        'img/content/visithospital-23.png',
        'img/content/visithospital-24.png',
        'img/content/visithospital-25.png',
        'img/content/visithospital-30.png',
        'img/content/visithospital-31.png',
       ];
    
      const [isOpen, setIsOpen] = useState(false);
      const [photoIndex, setPhotoIndex] = useState(0);
      const [loading, setLoading] = useState(false);
    
      const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
        setLoading(true);
      };
    
      const closeLightbox = () => {
        setIsOpen(false);
        setLoading(false);
      };
    
      const onImageLoad = () => {
        setLoading(false);
      };

      
  return (
    <>
      <Bredcrumb title="Visit Hospital" />
      <section className="facility-box mt-4">
        <div className="container">          
          <div className="row mb-4">
                    <div className="col-md-12">
                            <div className="gallery text-center row">
                            {images.map((image, index) => (
                            <div key={index} className="col-md-4 mb-4 mb-5">
                                <div className='gallery-block gallery-block1'>
                                    <img src={image} alt={`Image ${index + 1}`} onClick={() => openLightbox(index)} style={{cursor: 'pointer' }} />
                                </div>
                            </div>
                            ))}
                            {isOpen && (
                                <Lightbox
                                    mainSrc={images[photoIndex]}
                                    nextSrc={images[(photoIndex + 1) % images.length]}
                                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                    onCloseRequest={closeLightbox}
                                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                                    imageLoadErrorHandler={() => setLoading(false)}
                                    onImageLoad={onImageLoad}
                                />
                            )}
                            {loading && <div className="loading-indicator">Loading...</div>}

                            
                            </div>
                    </div>
                </div>	           
          </div>
        </section>
    </>
  )
}

export default VisitHospital
