import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const StudentOnlineServices = () => {
  return (
    <>
       <Bredcrumb title="Student Online Services" />
       <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 margin-30px-bottom sm-margin-25px-bottom">
                        <div className='justify-content-center d-flex'>
                            <div class="courses-box">
                                <div class="courses-pic position-relative">
                                    <a href="https://scholarship.up.gov.in/" target='blank'><img src="img/content/siocal.png" alt="" /></a>
                                </div>
                                <div class="course-main-content">
                                    <div class="courses-desc last-paragraph-no-margin d-flex flex-wrap gap-5 justify-content-center">
                                        <div class="courses-title pt-2 text-center">
                                            <h4 class="font-size22 sm-font-size20 margin-5px-bottom font-weight-500 pt-2"><Link to={'https://scholarship.up.gov.in/'} target='blank' class="text-theme-color">Scholarship</Link></h4>
                                            <p className="mt-2">
                                                <Link to={'https://scholarship.up.gov.in/'} target='blank' className="butn fill">
                                                  <span className="alt-font">Click here</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
                                                </Link>										
                                            </p>
                                        </div>
                                    </div>
                                </div>  
                            </div>                          
                          </div>
                    </div>
                    <div class="col-lg-3 col-md-6 margin-30px-bottom sm-margin-25px-bottom">
                        <div className='justify-content-center d-flex'>
                            <div class="courses-box">
                                <div class="courses-pic position-relative">
                                    <a href="https://uidai.gov.in/en/" target='blank'><img src="img/content/aadhar.png" alt="" /></a>
                                </div>
                                <div class="course-main-content">
                                    <div class="courses-desc last-paragraph-no-margin d-flex flex-wrap gap-5 justify-content-center">
                                        <div class="courses-title pt-2 text-center">
                                            <h4 class="font-size22 sm-font-size20 margin-5px-bottom font-weight-500 pt-2"><Link to={'https://uidai.gov.in/en/'} target='blank' class="text-theme-color">Aadhaar Card</Link></h4>
                                            <p className="mt-2">
                                                <Link to={'https://uidai.gov.in/en/'} target='blank' className="butn fill">
                                                  <span className="alt-font">Click here</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
                                                </Link>										
                                            </p>
                                        </div>
                                    </div>
                                </div>  
                            </div>                          
                          </div>
                    </div>
                    <div class="col-lg-3 col-md-6 margin-30px-bottom sm-margin-25px-bottom">
                        <div className='justify-content-center d-flex'>
                            <div class="courses-box">
                                <div class="courses-pic position-relative">
                                    <img src="img/content/pan.png" alt="" />
                                </div>
                                <div class="course-main-content">
                                    <div class="courses-desc last-paragraph-no-margin d-flex flex-wrap gap-5 justify-content-center">
                                        <div class="courses-title pt-2 text-center">
                                            <h4 class="font-size22 sm-font-size20 margin-5px-bottom font-weight-500 pt-2"><Link to={'#!'} class="text-theme-color">PAN Card</Link></h4>
                                            <p className="mt-2">
                                                <Link to={'#!'} className="butn fill">
                                                  <span className="alt-font">Click here</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
                                                </Link>										
                                            </p>
                                        </div>
                                    </div>
                                </div>  
                            </div>                          
                          </div>
                    </div>
                    <div class="col-lg-3 col-md-6 margin-30px-bottom sm-margin-25px-bottom">
                        <div className='justify-content-center d-flex'>
                            <div class="courses-box">
                                <div class="courses-pic position-relative">
                                    <a href="http://samajkalyan.up.gov.in/en" target='blank'><img src="img/content/dow.png" alt="" /></a>
                                </div>
                                <div class="course-main-content">
                                    <div class="courses-desc last-paragraph-no-margin d-flex flex-wrap gap-5 justify-content-center">
                                        <div class="courses-title pt-2 text-center">
                                            <h4 class="font-size22 sm-font-size20 margin-5px-bottom font-weight-500 pt-2"><Link to={'http://samajkalyan.up.gov.in/en'} target='blank' class="text-theme-color">Department of Social Welfare U.P.</Link></h4>
                                            <p className="mt-2">
                                                <Link to={'http://samajkalyan.up.gov.in/en'} target='blank' className="butn fill">
                                                  <span className="alt-font">Click here</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
                                                </Link>										
                                            </p>
                                        </div>
                                    </div>
                                </div>  
                            </div>                          
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default StudentOnlineServices
