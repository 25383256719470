import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const SCSTCommittee = () => {
  return (
    <>
      <Bredcrumb title="SC/ST STANDING COMMITTEE" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">                             
                              <div class="card">
                                <div class="card-body card-body-item">
                                  <div class="common-section-item">
                                    {/* <h2 class="common-section-title">Establishment of Committee for SC/ST</h2> */}
                                    <p>(As per the Schedule Caste and Scheduled Tribe (Prevention of Atrocities) Act, 1989)</p>
                                    {/* <h3>Members of SC/ST Standing Committee</h3> */}
                                    <table class="table table-striped">
                                    <thead>
                                      <tr>
                                          <th>Name</th>
                                          <th>Designation</th>
                                          <th>Contact no. /Email ID</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Dr. Zeashan Hussain</td>
                                        <td>Director</td>
                                        <td>9935844704 / zeashanmgip@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>Dr. Meena K Yadav</td>
                                        <td>Member</td>
                                        <td>9919991177 / meenayadav82@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>Dr. Raziuddin Khan</td>
                                        <td>Liaison officer (SC/ST Cell)</td>
                                        <td>8090183694 / pharmarazi@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>Mr. Mohd. Yusuf</td>
                                        <td>Member</td>
                                        <td>8543052518 / Yusufrbl2012@gmail.com</td>
                                    </tr> 
                                    <tr>
                                        <td>Mr. Rajesh Kumar Sharma</td>
                                        <td>Member</td>
                                        <td>9415929663 / rajeshmgip@gmail.com</td>
                                    </tr>                                    
                                    </tbody>
                                    </table>
                                  </div>                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Committees</h4>
                                <ul class="sidebar-links">
                                  <li><Link to={'/AntiDiscriminationCommittee'}>Anti Discrimination Committee</Link></li>
                                  <li><Link to={'/AntiRaggingCommittee'}>Anti Ragging Committee</Link></li>
                                  <li><Link to={'/SCSTCommittee'}>SC/ST Standing Committee</Link></li>
                                  <li><Link to={'/GenderSensitizationCommittee'}>Gender Sensitization Committee</Link></li>
                                  <li><Link to={'/StudentGrievanceRedressalCommittee'}>Student Grievance Redressal Committee</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default SCSTCommittee
