import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const DirectorMessage = () => {
  return (
    <>
      <Bredcrumb title="Director Message" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                          <p>Dear All,</p>
                          <p>MGIP comes into existence in 2008 with imaginativeness to become one of the esteemed organizations that is solely dedicated in the field of pharmaceutical education and research and to produce competent world class professional with glorious career.</p>
                          <p>With the commitment and focus as enunciated here, we are punctiliously raising the educational standards by offering you a world-class program, designed to help each student realize full potential at an affordable cost. We find it obligatory to take care of you in all aspects of life from now on leading to a lifetime relationship. Our teaching is focused on Pharmaceutical business world and its need through our personalized and interactive teaching methods. I am sure my committed faculty and staff will strive hard to transcend you all into prospective young pharmacists with globally compatible skills and knowledge.</p>
                          <p>Let's come and find what we mutually offer to each other!</p>
                          <p><h4>Dr. Zeashan Hussain</h4></p>
                          <p>Director</p>
                    </div>
                    <div class="col-lg-5">
                          <div class="xs-text-center mt-5">
                              <img src="img/content/director.png" alt="" class="border-radius-6 w-100" />
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default DirectorMessage
