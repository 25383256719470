import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const LatestUpdates = () => {
  return (
    <>
      <Bredcrumb title="Latest Updates" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb-1-9 mb-lg-0">
                      <ul className='p-2 list-style5'>
                          <li>								
                            <Link to={'/img/pdf/NPD_2024.pdf'} target='blank' className='text-dark'> 
                                <p>A Brief summary on 4th National Pharmacovigilance Week 2024. <span className='blink'>New</span></p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/WPD_2024.pdf'} target='blank' className='text-dark'> 
                                <p>A Brief summary on World Pharmacist Day 2024. <span className='blink'>New</span></p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/notice1.pdf'} target='blank' className='text-dark'> 
                                <p>Guest Lecture on "Future Prospects for Pharmacy Graduates" dated 1st May 2024.</p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/notice8.pdf'} target='blank' className='text-dark'> 
                                <p>Notice for the students of B. Pharm last year (Session 2023-24).</p>
                            </Link>								
                          </li>
                          {/* <li>								
                            <Link to={'/img/pdf/notice5.pdf'} target='blank' className='text-dark'> 
                                <p>Time Table for B. Pharm VIth Semester (Session 2023-24).</p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/notice6.pdf'} target='blank' className='text-dark'> 
                              <p>Time Table for B. Pharm IVth Semester (Session 2023-24).</p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/notice7.pdf'} target='blank' className='text-dark'> 
                              <p>Important notice for scholarship.</p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/notice8.pdf'} target='blank' className='text-dark'> 
                              <p>Notice for B. Pharm I, II, and III year students.</p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/notice9.pdf'} target='blank' className='text-dark'> 
                              <p>Notice for Holi Festival.</p>
                            </Link>								
                          </li>
                          <li>								
                            <Link to={'/img/pdf/notice10.pdf'} target='blank' className='text-dark'> 
                              <p>Notice for the students of B. Pharm last year (Session 2023-24).</p>
                            </Link>								
                          </li>		
                          <li>								
                            <Link to={'/img/pdf/notice11.pdf'} target='blank' className='text-dark'> 
                              <p>Notice for Good Friday dated 29th March 2024.</p>
                            </Link>								
                          </li>	 */}
                          </ul>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default LatestUpdates
