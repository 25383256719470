import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const WiFiCampus = () => {
  return (
    <>
      <Bredcrumb title="Wi-Fi Campus" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>21st Century belongs to “Era of Web Information” as Internet plays backbone for every aspect of life. Understanding the need and importance of the Internet in the field of education is supported by round the clock High Speed Internet Connectivity throughout the campus with the help of which the students can surf the Web and can enhance their knowledge as well as extract unlimited e-information.</p>
                                <p>In addition to this, the students are also being provided Wireless connectivity (Wi-Fi) using which the students can access online information anywhere in and around the campus using their Laptops. The entire campus is Wi-Fi enabled with high speed internet connection to allow the students to access the internet no-matter wherever they are. The coverage is not just limited to the classrooms; instead it extends to all the facilities within the campus premises.</p> 
                                <p>Students and faculty are free to access Wi-Fi. Staff and Students are informed to utilize the Wi- Fi facility by registering their i-connect devices for the active usage of the facility. This helps the students to prepare papers on the latest technologies to be presented in various symposiums and seminars. Wi-Fi is available round the clock in the entire campus, to facilitate the students to concentrate on their academics and research works without any constraints.</p>                                                     
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/wifi-campus.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/wifi-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/wifi-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default WiFiCampus
