import React from 'react'
import { } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const PharmaceuticalChemistry = () => {
  return (
    <>
      <Bredcrumb title="Pharmaceutical Chemistry Lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">                        
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>The Pharmaceutical Chemistry lab is the backbone of the institute. It deals with study of compounds’ structure, synthesis, identification and characterization. The laboratory is equipped with sophisticated instruments like HPLC, UV-Visible Spectrophotometer (Double Beam), KF Titrator, Flame Photometer, Conductivity meter, pH Meter, MP Apparatus, Colorimeter, Flourimeter etc.</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_chemistry_01.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_chemistry_02.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_chemistry_03.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_chemistry_04.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_chemistry_05.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_chemistry_06.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                                <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default PharmaceuticalChemistry
