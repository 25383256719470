import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
const Navbar = () => {
	const location = useLocation();
		const [clicked, setClickedMenu] = useState(false);
		useEffect(() => {
		AOS.init({ duration: 500, easing: "ease", once: true });
	}, []);
	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "instant" });
		setClickedMenu(false);
	}, [location.pathname]);
		const [isSticky, setIsSticky] = useState(false);
		useEffect(() => {
		const handleScroll = () => {
		const scrollPosition = window.scrollY;
		if (scrollPosition > 100) {
		// Adjust this value based on when you want the header to become sticky
		setIsSticky(true);
	} else {
		setIsSticky(false);
	}
	};
		window.addEventListener("scroll", handleScroll);
		return () => {
		window.removeEventListener("scroll", handleScroll);
	};
	}, []);
		let showMenu = () => {
		setClickedMenu(!clicked);
	};
		const [isVisible, setIsVisible] = useState(false);
		const handleScroll = () => {
		setIsVisible(window.pageYOffset > 100);
	};
		const scrollToTop = () => {
		window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
	};
		useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
		window.removeEventListener("scroll", handleScroll);
	};
}, []);
return (
    <>
      <header className="header-style1 menu_area-light">
        <div id="top-bar" class="bg-primary-light">
          <div class="container px-lg-1-6 px-xl-2-5 px-xxl-2-9 pb-2">
            <div class="row align-items-center">
              <div class="col-md-9">
                <div class="top-bar-info">
                  <ul class="ps-0">
                    <li>
                      <i class="fas fa-mobile-alt"></i>+91 941 592 9663 +91 830 320 8180
                    </li>
                    <li class="d-none d-sm-inline-block">
                      <i class="fas fa-envelope"></i>
                        <Link href="mailto:director.mgip@gmail.com" class="text-white">
                          director.mgip@gmail.com
                        </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 d-none d-md-block">
                <ul class="top-social-icon ps-0">
                  <li>
                    <Link
                      to={"https://www.facebook.com/mgip391?ref=embed_page"}
                      target="blank"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="tw_svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link to={ "https://www.instagram.com/mgipofficial?igsh=MWdua3J1cnZwcnF0cA=="} target="blank">
                      <i class="fab fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"https://www.linkedin.com/school/mahatma-gandhi-institute-of-pharmacy-lucknow/"} target="blank">
                      <i class="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"https://www.youtube.com/@mahatmagandhiinstituteofph"}
                      target="blank"
                    >
                      <i class="fab fa-youtube"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="top-head d-flex justify-content-start  gap-4">
              <div>
                <Link to={"/"} className="navbar-brand">
                  <img id="logo" src="img/logo.png" alt="logo" />
                </Link>
              </div>
              <div className="d-none d-lg-block">
                <div className="top-head2">
                  Mahatma Gandhi Institute of Pharmacy
                </div>
                <p className="mb-0 top-text-new text-center">
                Affiliated With Dr. A.P.J. Abdul Kalam Technical University Lucknow & Approved By Pharmacy Council of India
                </p>
              </div>
              <div className="d-lg-none d-sm-block">
                <div className="top-head2">MGIP Lucknow</div>
              </div>
            </div>
          </div>
        </div>
        <div className={isSticky ? "sticky-header" : "navbar-default"}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-12 p-0">
                <div className="menu_area alt-font">
                  <nav className="navbar navbar-expand-lg navbar-light no-padding justify-content-start">
                    <Link to={"/"} className="navbar-brand">
                      <img id="logo" src="img/logo.png" alt="logo" />
                    </Link>
                    <div class="navbar-toggler" onClick={showMenu}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512">
                        <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                      </svg>
                    </div>
                    <ul className="navbar-nav mr-auto" id="nav">
                      <li>
                        <Link to={"/"}>Home</Link>
                      </li>
                      <li className="has-sub">
                        <Link to={"/About"}>About Us</Link>
                        <ul>
                          <li>
                            <Link to={"/About"}>About MGIP</Link>
                          </li>
                          <li>
                            <Link to={"/VisionMission"}>Vision & Mission</Link>
                          </li>
                          <li>
                            <Link to={"/ChairpersonMessage"}>
                              Chairperson Message
                            </Link>
                          </li>
                          <li>
                            <Link to={"/DirectorMessage"}>
                              Director Message
                            </Link>
                          </li>
                          <li>
                            <Link to={"/PrincipalMessage"}>
                              Principal Message
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-sub">
                        <Link>Approval</Link>
                        <ul>
                          <li>
                            <Link
                              to={"/img/pdf/PCI_Approval.pdf"}
                              target="blank"
                            >
                              PCI
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/img/pdf/BTE_Approval.pdf"}
                              target="blank"
                            >
                              BTE
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/img/pdf/AKTU_Affiliation.pdf"}
                              target="blank"
                            >
                              AKTU
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-sub">
                        <Link to={"/Courses"}>Admissions</Link>
                        <ul>
                          <li>
                            <Link to={"/Courses"}>Courses</Link>
                          </li>
                          <li>
                            <Link to={"/FeeStructure"}>Fee Structure</Link>
                          </li>
                          <li>
                            <Link
                              to={"/img/pdf/Admission_Form.pdf"}
                              target="blank"
                            >
                              Admission Form
                            </Link>
                          </li>
                          <li>
                            <Link to={"/img/pdf/brochure.pdf"} target="blank">
                              Brochure
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-sub">
                        <Link to={"/AntiDiscriminationCommittee"}>
                          Committees
                        </Link>
                        <ul>
                          <li>
                            <Link to={"/AntiDiscriminationCommittee"}>
                              Anti Discrimination Committee
                            </Link>
                          </li>
                          <li>
                            <Link to={"/AntiRaggingCommittee"}>
                              Anti Ragging Committee
                            </Link>
                          </li>
                          <li>
                            <Link to={"/SCSTCommittee"}>
                              SC/ST Standing Committee
                            </Link>
                          </li>
                          <li>
                            <Link to={"/GenderSensitizationCommittee"}>
                              Gender Sensitization Committee
                            </Link>
                          </li>
                          <li>
                            <Link to={"/StudentGrievanceRedressalCommittee"}>
                              Student Grievance Redressal Committee
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-sub">
                        <Link to={"/PharmaceuticsLab"}>Facilities</Link>
                        <ul>
                          <li>
                            <Link to={"/PharmaceuticsLab"}>
                              Pharmaceutics Lab
                            </Link>
                          </li>
                          <li>
                            <Link to={"/Pharmacologylab"}>
                              Pharmacology Lab
                            </Link>
                          </li>
                          <li>
                            <Link to={"/Pharmacognosylab"}>
                              Pharmacognosy Lab
                            </Link>
                          </li>
                          <li>
                            <Link to={"/PharmaceuticalAnalysis"}>
                              Pharmaceutical Analysis Lab
                            </Link>
                          </li>
                          <li>
                            <Link to={"/PharmaceuticalChemistry"}>
                              Pharmaceutical Chemistry Lab
                            </Link>
                          </li>
                          <li>
                            <Link to={"/HumanAnatomyPathology"}>
                              Human Anatomy and Pathology Lab
                            </Link>
                          </li>
                          <li>
                            <Link to={"/MicrobiologyLab"}>
                              Microbiology Lab
                            </Link>
                          </li>
                          <li>
                            <Link to={"/ComputerLab"}>Computer Lab</Link>
                          </li>
                          <li>
                            <Link to={"/HerbalGarden"}>Herbal Garden</Link>
                          </li>
                          <li>
                            <Link to={"/MachineRoom"}>Machine Room</Link>
                          </li>
                          <li>
                            <Link to={"/CentralInstrumentRoom"}>
                              Central Instrument Room
                            </Link>
                          </li>
                          <li>
                            <Link to={"/Library"}>Library</Link>
                          </li>
                          <li>
                            <Link to={"/Auditorium"}>Auditorium</Link>
                          </li>
                          <li>
                            <Link to={"/Cafeteria"}>Cafeteria</Link>
                          </li>
                          <li>
                            <Link to={"/Transport"}>Transport</Link>
                          </li>
                          <li>
                            <Link to={"/Hostel"}>Hostel</Link>
                          </li>
                          <li>
                            <Link to={"/WiFiCampus"}>Wi-Fi Campus</Link>
                          </li>
                          <li>
                            <Link to={"/Sports"}>Sports</Link>
                          </li>
                          <li>
                            <Link to={"/EmergencyMedicare"}>
                              Emergency Medicare
                            </Link>
                          </li>
                          <li>
                            <Link to={"/BankingFacility"}>
                              Banking Facility
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="has-sub">
                        <Link to={"/PlacementsData"}>Placements</Link>
                        <ul>
                          <li>
                            <Link to={"/PlacementsData"}>Placements Data</Link>
                          </li>
                          <li>
                            <Link to={"/TrainingPlacements"}>
                              Training and Placements Cell
                            </Link>
                          </li>
                          <li>
                            <Link to={"/OurRecruiter"}>Our Recruiters</Link>
                          </li>
                          <li>
                            <Link to={"/IndustrialVisit"}>
                              Industrial visit
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-sub">
                        <Link>Achievements</Link>
                        <ul>
                          <li>
                            <Link
                              to={
                                "https://erp.aktu.ac.in/WebPages/OneView/OneView.aspx"
                              }
                              target="blank"
                            >
                              Results
                            </Link>
                          </li>
                          <li>
                            <Link to={"/AwardsAchievements"}>
                              Awards & Achievements
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-sub">
                        <Link to={"/Events"}>Media</Link>
                        <ul>
                          <li>
                            <Link to={"/Events"}>Events</Link>
                          </li>
                          <li>
                            <Link to={"/Photo"}>Photo</Link>
                          </li>
                          <li>
                            <Link to={"/Video"}>Video</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to={"/Contact"}>Contact</Link>
                      </li>
                    </ul>

                    {clicked && (
                      <ul
                        className="navbar-nav ms-auto"
                        id="nav"
                        style={{ display: "block" }}
                      >
                        <li>
                          <Link to={"/"}>Home</Link>
                        </li>
                        <li className="has-sub">
                          <Link to={"/About"}>About Us</Link>
                          <ul>
                            <li>
                              <Link to={"/About"}>About MGIP</Link>
                            </li>
                            <li>
                              <Link to={"/VisionMission"}>
                                Vision & Mission
                              </Link>
                            </li>
                            <li>
                              <Link to={"/ChairpersonMessage"}>
                                Chairperson Message
                              </Link>
                            </li>
                            <li>
                              <Link to={"/DirectorMessage"}>
                                Director Message
                              </Link>
                            </li>
                            <li>
                              <Link to={"/PrincipalMessage"}>
                                Principal Message
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link>Approval</Link>
                          <ul>
                            <li>
                              <Link
                                to={"/img/pdf/PCI_Approval.pdf"}
                                target="blank"
                              >
                                PCI
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"/img/pdf/BTE_Approval.pdf"}
                                target="blank"
                              >
                                BTE
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"/img/pdf/AKTU_Affiliation.pdf"}
                                target="blank"
                              >
                                AKTU
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link to={"/Courses"}>Admissions</Link>
                          <ul>
                            <li>
                              <Link to={"/Courses"}>Courses</Link>
                            </li>
                            <li>
                              <Link to={"/FeeStructure"}>Fee Structure</Link>
                            </li>
                            <li>
                              <Link
                                to={"/img/pdf/Admission_Form.pdf"}
                                target="blank"
                              >
                                Admission Form
                              </Link>
                            </li>
                            <li>
                              <Link to={"/img/pdf/brochure.pdf"} target="blank">
                                Brochure
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link to={"/AntiDiscriminationCommittee"}>
                            Committees
                          </Link>
                          <ul>
                            <li>
                              <Link to={"/AntiDiscriminationCommittee"}>
                                Anti Discrimination Committee
                              </Link>
                            </li>
                            <li>
                              <Link to={"/AntiRaggingCommittee"}>
                                Anti Ragging Committee
                              </Link>
                            </li>
                            <li>
                              <Link to={"/SCSTCommittee"}>
                                SC/ST Standing Committee
                              </Link>
                            </li>
                            <li>
                              <Link to={"/GenderSensitizationCommittee"}>
                                Gender Sensitization Committee
                              </Link>
                            </li>
                            <li>
                              <Link to={"/StudentGrievanceRedressalCommittee"}>
                                Student Grievance Redressal Committee
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link to={"/PharmaceuticsLab"}>Facilities</Link>
                          <ul>
                            <li>
                              <Link to={"/PharmaceuticsLab"}>
                                Pharmaceutics Lab
                              </Link>
                            </li>
                            <li>
                              <Link to={"/Pharmacologylab"}>
                                Pharmacology Lab
                              </Link>
                            </li>
                            <li>
                              <Link to={"/Pharmacognosylab"}>
                                Pharmacognosy Lab
                              </Link>
                            </li>
                            <li>
                              <Link to={"/PharmaceuticalAnalysis"}>
                                Pharmaceutical Analysis Lab
                              </Link>
                            </li>
                            <li>
                              <Link to={"/PharmaceuticalChemistry"}>
                                Pharmaceutical Chemistry Lab
                              </Link>
                            </li>
                            <li>
                              <Link to={"/HumanAnatomyPathology"}>
                                Human Anatomy and Pathology Lab
                              </Link>
                            </li>
                            <li>
                              <Link to={"/MicrobiologyLab"}>
                                Microbiology Lab
                              </Link>
                            </li>
                            <li>
                              <Link to={"/ComputerLab"}>Computer Lab</Link>
                            </li>
                            <li>
                              <Link to={"/HerbalGarden"}>Herbal Garden</Link>
                            </li>
                            <li>
                              <Link to={"/MachineRoom"}>Machine Room</Link>
                            </li>
                            <li>
                              <Link to={"/CentralInstrumentRoom"}>
                                Central Instrument Room
                              </Link>
                            </li>
                            <li>
                              <Link to={"/Library"}>Library</Link>
                            </li>
                            <li>
                              <Link to={"/Auditorium"}>Auditorium</Link>
                            </li>
                            <li>
                              <Link to={"/Cafeteria"}>Cafeteria</Link>
                            </li>
                            <li>
                              <Link to={"/Transport"}>Transport</Link>
                            </li>
                            <li>
                              <Link to={"/Hostel"}>Hostel</Link>
                            </li>
                            <li>
                              <Link to={"/WiFiCampus"}>Wi-Fi Campus</Link>
                            </li>
                            <li>
                              <Link to={"/Sports"}>Sports</Link>
                            </li>
                            <li>
                              <Link to={"/EmergencyMedicare"}>
                                Emergency Medicare
                              </Link>
                            </li>
                            <li>
                              <Link to={"/BankingFacility"}>
                                Banking Facility
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="has-sub">
                          <Link to={"/PlacementsData"}>Placements</Link>
                          <ul>
                            <li>
                              <Link to={"/PlacementsData"}>
                                Placements Data
                              </Link>
                            </li>
                            <li>
                              <Link to={"/TrainingPlacements"}>
                                Training and Placements Cell
                              </Link>
                            </li>
                            <li>
                              <Link to={"/OurRecruiter"}>Our Recruiters</Link>
                            </li>
                            <li>
                              <Link to={"/IndustrialVisit"}>
                                Industrial visit
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link>Achievements</Link>
                          <ul>
                            <li>
                              <Link
                                to={
                                  "https://erp.aktu.ac.in/WebPages/OneView/OneView.aspx"
                                }
                                target="blank"
                              >
                                Results
                              </Link>
                            </li>
                            <li>
                              <Link to={"/AwardsAchievements"}>
                                Awards & Achievements
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link to={"/Events"}>Media</Link>
                          <ul>
                            <li>
                              <Link to={"/Events"}>Events</Link>
                            </li>
                            <li>
                              <Link to={"/Photo"}>Photo</Link>
                            </li>
                            <li>
                              <Link to={"/Video"}>Video</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to={"/Contact"}>Contact</Link>
                        </li>
                      </ul>
                    )}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <button
        className={`scroll-to-top ${isVisible ? "visible" : ""}`}
        onClick={scrollToTop}
      >
        <i className="fas fa-angle-up" aria-hidden="true"></i>
      </button>
    </>
  );
};

export default Navbar;
