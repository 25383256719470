import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const PlacementRecord2023 = () => {
  return (
    <>
    <Bredcrumb title="Placement Record 2022-23" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8">                       
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    <th>NAME OF STUDENTS</th>
                                    <th>DEPARTMENT</th>
                                    <th>COMPANY</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1.</td>
                                <td>Abdul Azad Ansari</td>
                                <td>Pharmacist</td>
                                <td>J.J Hospital Siddarth Nagar</td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>Abhisek Kushwaha </td>
                                <td>Pharmacist</td>
                                <td>Buddha Medical.</td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>Akash Gautam </td>
                                <td>Pharmacist</td>
                                <td>Poinear Medical Kanpur.</td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>Akash Maurya </td>
                                <td>Pharmacist</td>
                                <td>Grace Hospital Raibareilly</td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td>Aman Singh</td>
                                <td>Pharmacist</td>
                                <td>Surya Hospital</td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td>Anuj Maurya</td>
                                <td>Medical Coder</td>
                                <td>Cooro Health, Noida</td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td>Arvind Maurya</td>
                                <td>Medical Coder</td>
                                <td>Cooro Health, Noida</td>
                            </tr>
                            <tr>
                                <td>8.</td>
                                <td>Gaurav Singh</td>
                                <td>Medical Coder</td>
                                <td>Cooro Health, Noida</td>
                            </tr>
                            <tr>
                                <td>9.</td>
                                <td>Nishant Dixit</td>
                                <td>Management Trainee</td>
                                <td>Glenstone Healthcare</td>
                            </tr>
                            <tr>
                                <td>10.</td>
                                <td>VinayBharti</td>
                                <td>Trainee Officer in Production</td>
                                <td>Unichem Laboratories Ltd.</td>
                            </tr>
                            <tr>
                                <td>11.</td>
                                <td>Shashank Singh</td>
                                <td>Trainee Officer in Production</td>
                                <td>Cipla Pharmaceutical Pvt. Ltd.</td>
                            </tr>
                            <tr>
                                <td>12.</td>
                                <td>Mritunjay jaiswal</td>
                                <td>Production Department</td>
                                <td>East African Overseas</td>
                            </tr>
                            <tr>
                                <td>13.</td>
                                <td>Anup Kumar Pal</td>
                                <td>Business Officer</td>
                                <td>British Biologicals</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Placement</h4>
                                <ul class="sidebar-links">
                                    <li><Link to={'/PlacementRecord202324'}>Placement Record 2023-24</Link></li>
                                    <li><Link to={'/PlacementRecord2023'}>Placement Record 2022-23</Link></li>                                    
                                    <li><Link to={'/PlacementRecord201617'}>Placement Record 2016-17</Link></li>                                   
                                    <li><Link to={'/PlacmentGraph'}>Placment Graph</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                    </div>
              </div>
          </div>
      </section>
  </>
  )
}

export default PlacementRecord2023
