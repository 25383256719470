import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const GenderSensitizationCommittee = () => {
  return (
    <>
       <Bredcrumb title="Gender Sensitization Committee" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">                              
                              <div class="card">
                                <div class="card-body card-body-item">
                                  <div class="common-section-item">
                                    {/* <h2 class="common-section-title">Gender Sensitization Committee</h2> */}
                                    <p>The following members are nominated as the committee for Gender Sensitization Cell for</p>
                                    
                                    <p><h4>SESSION-2023-24</h4></p>
                                    <table class="table table-striped">
                                    <thead>
                                      <tr>
                                          <th>S.N.</th>
                                          <th>Designation</th>
                                          <th>Name</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>Directed by</td>
                                        <td>Dr. Zeashan Hussain</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Coordinator</td>
                                        <td>Dr. Meena K Yadav</td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Coordinator</td>
                                        <td>Ms. Shafkeen Siddiqui</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Coordinator</td>
                                        <td>Ms. Seema Kushwaha</td>
                                    </tr>                                    
                                    </tbody>
                                    </table>
                                    <p><h4>Duties and Responsibilities:</h4></p>
                                    <p>To examine all grievance letters received from the women staff/students regarding the sexual harassment. To give feedback to the women staff/students concerned to find solution for their grievances. The committee will record such grievances received from the women staff/students, in a separate register maintained exclusively for this purpose. The reply given by the committee to the women staff/students for grievance should also be recorded in the register. The Committee should find suitable solutions to settle the problems faced by the women staff/students in regard to matters relating to sexual harassment within 10 days' after thorough investigation.</p>
                                  </div>                                 
                                </div>
                              </div>                             
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Committees</h4>
                                <ul class="sidebar-links">
                                  <li><Link to={'/AntiDiscriminationCommittee'}>Anti Discrimination Committee</Link></li>
                                  <li><Link to={'/AntiRaggingCommittee'}>Anti Ragging Committee</Link></li>
                                  <li><Link to={'/SCSTCommittee'}>SC/ST Standing Committee</Link></li>
                                  <li><Link to={'/GenderSensitizationCommittee'}>Gender Sensitization Committee</Link></li>
                                  <li><Link to={'/StudentGrievanceRedressalCommittee'}>Student Grievance Redressal Committee</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default GenderSensitizationCommittee
