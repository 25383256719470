import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const BankingFacility = () => {
  return (
    <>
      <Bredcrumb title="Banking Facility" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>The Institute and Hostel Fee can also made by NEFT/RTGS. Bank details are as follows:</p>                                                      
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-6 mb-3">
                                    <div className="fees__box">                                        
                                        <h3>For Institute Fee B.Pharm:-</h3>
                                        <p>A/c Holder Name:- Mahatma Gandhi Institute of Pharmacy </p>
                                        <p>Bank Name: UNION BANK OF INDIA</p>
                                        <p>Bank IFSC Code: UBIN0916218</p>
                                        <p>Branch: Junabganj</p>
                                        <p>Account No. 560131000178670</p>
                                    </div>
                                </div>                                
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-6 mb-3">
                                    <div className="fees__box">
                                        <h3>For Institute Fee D.Pharm :-</h3>
                                        <p>A/c Holder Name:- Mahatma Gandhi Institute of Pharmacy</p>
                                        <p>Bank Name: UNION BANK OF INDIA</p>
                                        <p>Bank IFSC Code: UBIN0916218</p>
                                        <p>Branch: Junabganj</p>
                                        <p>Account No. 510101006046095</p>
                                    </div>
                                </div>                                
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-6 mb-3">
                                    <div className="fees__box">
                                        <h3>For Institute Hostel Fee :-</h3>
                                        <p>A/c Holder Name:- Mahatma Gandhi Institute of Pharmacy</p>
                                        <p>Bank Name: UNION BANK OF INDIA</p>
                                        <p>Bank IFSC Code: UBIN0916218</p>
                                        <p>Branch: Junabganj</p>
                                        <p>Account No. 510101006046486</p>
                                    </div>
                                </div>                                
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default BankingFacility
