import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'

const PlacementDetail2021 = () => {
  return (
    <>
    <Bredcrumb title="Placement Detail 2022" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8">
                       <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    <th>Organization / Company</th>
                                    <th>Department</th>
                                    <th>Package</th>
                                    <th>Students</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1.</td>
                                <td>MARC LABS Ltd</td>
                                <td>MARKETING</td>
                                <td>2.3 LAKH PER ANNUM</td>
                                <td>SHIVAM DWIVEDI</td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>MARC LABS Ltd</td>
                                <td>MARKETING</td>
                                <td>2.3 LAKH PER ANNUM</td>
                                <td>AVINEESH MISHRA</td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>MARC LABS Ltd</td>
                                <td>MARKETING</td>
                                <td>2.3 LAKH PER ANNUM</td>
                                <td>AZHAR IQBAL</td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>TROIKAA PHARMACEUTICAL Ltd.</td>
                                <td>LAKH</td>
                                <td>2.2  LAKH PER ANNUM</td>
                                <td>AVINASH KUMAR VERMA</td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td>MARC LABS Ltd.</td>
                                <td>MARKETINGC</td>
                                <td>2.4 LAKH PER ANNUM</td>
                                <td>ASHUTOSH MISHRA</td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td>MARC LABS Ltd.</td>
                                <td>MARKETING</td>
                                <td>2.3 LAKH PER ANNUM</td>
                                <td>PRADHUMAN SINGH</td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td>MARC LABS Ltd.</td>
                                <td>MARKETING</td>
                                <td>2.3 L LAKH PER ANNUM</td>
                                <td>SATISH KUMAR</td>
                            </tr>
                            <tr>
                                <td>8.</td>
                                <td>MARC LABS Ltd.</td>
                                <td>MARKETING</td>
                                <td>2.3 Lakh per annum</td>
                                <td>GURUMEL SINGH KANAUJIA</td>
                            </tr>   
                            <tr>
                                <td>9.</td>
                                <td>TROIKAA PHARMACEUTICAL Ltd.</td>
                                <td>MARKETING</td>
                                <td>2.3 Lakh per annum</td>
                                <td>MAINUDDIN</td>
                            </tr> 
                            <tr>
                                <td>10.</td>
                                <td>NOUVEAU MEDICAMENT Ltd.</td>
                                <td>MARKETING</td>
                                <td>2.4 Lakh per annum</td>
                                <td>PRADUMAN SINGH</td>
                            </tr> 
                            <tr>
                                <td>11.</td>
                                <td>NOUVEAU MEDICAMENT Ltd.</td>
                                <td>MARKETING</td>
                                <td>2.4 Lakh per annum</td>
                                <td>SATISH KUMAR</td>
                            </tr> 
                            <tr>
                                <td>12.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>SHIVA  SHUKLA</td>
                            </tr> 
                            <tr>
                                <td>13.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>APEKSHA SINGH</td>
                            </tr>  
                            <tr>
                                <td>14.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>PRATIKSHA SRIVASTAVA</td>
                            </tr> 
                            <tr>
                                <td>15.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>FARHAD SIDDIQUI</td>
                            </tr>   
                            <tr>
                                <td>16.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>NAJEEBULLAH</td>
                            </tr> 
                            <tr>
                                <td>17.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>GURUMEL SINGH KANAUJIA</td>
                            </tr> 
                            <tr>
                                <td>18.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>CHAMAN AHMAD</td>
                            </tr> 
                            <tr>
                                <td>19.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>MONIKA TIWARI</td>
                            </tr> 
                            <tr>
                                <td>20.</td>
                                <td>QUANTYS CLINICAL Pvt. Ltd.</td>
                                <td>CLINICAL TRAILS</td>
                                <td>1.8 Lakh per annum</td>
                                <td>VEENA PRIYA NAIR</td>
                            </tr>                                                                           
                        </tbody>
                        </table>
                    </div>
                    <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Placement</h4>
                                <ul class="sidebar-links">
                                    <li><Link to={'/PlacementRecord2023'}>Placement Record 2022-23</Link></li>
                                    <li><Link to={'/PlacementRecord2022'}>Placement Record 2023</Link></li>
                                    <li><Link to={'/PlacementDetail2022'}>Placement Detail 2022</Link></li>
                                    <li><Link to={'/PlacementDetail2021'}>Placement Detail 2021</Link></li>
                                    <li><Link to={'/PlacmentGraph'}>Placment Graph</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                    </div>
              </div>
          </div>
      </section>
  </>
  )
}

export default PlacementDetail2021
