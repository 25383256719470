import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const StudentGrievanceRedressalCommittee = () => {
  return (
    <>
       <Bredcrumb title="Student Grievance Redressal Committee" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">                            
                              <div class="card">
                                <div class="card-body card-body-item">
                                  <div class="common-section-item">
                                    {/* <h2 class="common-section-title">Student Grievance Redressal Committee</h2> */}
                                    <p><h4>SESSION-2023-24</h4></p>
                                    <p>It is for information of Faculty, Staff and Students of MGIP, Lucknow that we have constituted student Grievance Redressal Committee to look into their grievances and provide them an early solution. The committee shall constitute of the following staff.</p>
                                    <table class="table table-striped">
                                    <thead>
                                      <tr>
                                          <th>S.N.</th>
                                          <th>Name</th>
                                          <th>Designation</th>
                                          <th></th>
                                          <th>Contact no.</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>Dr. Zeashan Hussain</td>
                                        <td>Director</td>
                                        <td>Convener</td>
                                        <td>9935844704</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Dr. Meena K Yadav</td>
                                        <td>Professor </td>
                                        <td>Member</td>
                                        <td>9919991177</td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Dr. Raziuddin Khan</td>
                                        <td>Professor </td>
                                        <td>Member</td>
                                        <td>8090183694</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Mr. Anand Kumar Srivastava</td>
                                        <td>Associate Professor</td>
                                        <td>Member</td>
                                        <td>9369229157</td>
                                    </tr>                                 
                                    </tbody>
                                    </table>
                                    <p>The Students Grievance Redressal Cell is located in the office of the Institute, In case of any grievance, the students are required to submit the application on the prescribed form on E-mail ID (director.mgip@gmail.com) duly forwarded by the respective Class teachers. The detailed information on the objective, function & scope of the student grievance redressal cell and is also available on the Institution's website.</p>
                                  </div>                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Committees</h4>
                                <ul class="sidebar-links">
                                  <li><Link to={'/AntiDiscriminationCommittee'}>Anti Discrimination Committee</Link></li>
                                  <li><Link to={'/AntiRaggingCommittee'}>Anti Ragging Committee</Link></li>
                                  <li><Link to={'/SCSTCommittee'}>SC/ST Standing Committee</Link></li>
                                  <li><Link to={'/GenderSensitizationCommittee'}>Gender Sensitization Committee</Link></li>
                                  <li><Link to={'/StudentGrievanceRedressalCommittee'}>Student Grievance Redressal Committee</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default StudentGrievanceRedressalCommittee
