import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const CentralInstrumentRoom = () => {
  return (
    <>
      <Bredcrumb title="Central Instrument Room" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>A central instrument room serves as a pivotal hub within a facility, housing a wide array of instruments and equipment essential for research, analysis, testing, or manufacturing processes. This specialized room is meticulously organized to store, manage, and maintain instruments ranging from spectrophotometers and chromatography systems to microscopes, centrifuges, and other sophisticated tools. One of its primary functions is to ensure the accuracy and reliability of experimental or analytical results by implementing regular calibration, maintenance, and quality control measures for all instruments.</p>                                                      
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/central-instrument-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/central-instrument-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/central-instrument-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>                            
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default CentralInstrumentRoom
