import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const Careers = () => {
  return (
    <>
      <Bredcrumb title="Careers" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb-1-9 mb-lg-0">
                          <p>We offer an extensive variety of career choices. We can keep you satisfied with challenging responsibilities, professional development and the opportunity to work with the best in the industry.</p>
                          <p>At MGIP, we will value your ideals, reward your achievements and encourage your pioneering spirit. If you are interested in exploring opportunities at MGIP, write us at <Link to={'mailto:director.mgip@gmail.com'}>director.mgip@gmail.com</Link></p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Careers
