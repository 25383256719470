import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const IndustrialVisit = () => {
  return (
    <>
      <Bredcrumb title="Industrial Visit" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb-1-9 mb-lg-0">
                          <p>The College organizes industrial visits for students to update with latest updates on the industrial profession and to provide knowledge of new modern techniques used in the Pharmaceutical Industry. Students visited the renowned pharmaceutical industry, which helps students generate new ideas for research.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-3">
                        <div className="gallary-block">
                            <div className="position-relative">
                                <img src="img/content/fieldvisitnbri-02.png" alt="..." />                                           
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-3">
                        <div className="gallary-block">
                            <div className="position-relative">
                                <img src="img/content/fieldvisitnbri-03.png" alt="..." />                                           
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-3">
                        <div className="gallary-block">
                            <div className="position-relative">
                                <img src="img/content/fieldvisitnbri-04.png" alt="..." />                                           
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-3">
                        <div className="gallary-block">
                            <div className="position-relative">
                                <img src="img/content/cimap-02.png" alt="..." />                                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default IndustrialVisit
