import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Slider = () => {
    const options = {
        items: 1, // Set the number of items to display
        autoplay: true,
        autoplayTimeout: 3000, // Time between slides in milliseconds
        loop: true, // Enable loop
        nav: false, // Enable navigation arrows
    };
   
    let arr=[ 
        {
            img:'/img/slider/07.png',       
        },

        {
            img:'/img/slider/01.png',       
        },
        {
            img:'/img/slider/02.png',       
        },

        {
            img:'/img/slider/08.png',       
        },
            {
            img:'/img/slider/03.png',        
        },    

        {
            img:'/img/slider/04.png',       
        },   
        
        {
            img:'/img/slider/05.png',        
        },  
    ]
    
  return (
    <div class="container-fluid">
		<div class="row slider-fade">
            <div className='col-md-12 p-0'>
            <OwlCarousel className='owl-theme portfolio1-carousel owl-carousel text-center' {...options}>
                {arr.map((item)=>{
                    return  <div class="text-center item bg-img" data-overlay-dark="6"  style={{ backgroundImage: 'url(' + item.img + ')'}}></div>
                
                })} 
             </OwlCarousel>  
             </div>
		</div>
		<div class="row">
			<div class="col-md-12 p-0">
				<div class="news-ticker" id="news-ticker">					
					  <div class="news-item font-weight-600">Admissions are open for the session 2024-25 in M.Pharm, B.Pharm & D.Pharm. Feel free to contact us at +91 9935844704, +91 9415929663, and +91 9305864359</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Slider
