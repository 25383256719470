import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const Faculty = () => {
  return (
    <>
      <Bredcrumb title="Faculty" />
       <section className='courses-area'>
            <div className="container">                
                
                <div className="row">
                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/zeashan.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Dr. Zeashan Hussain</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmacognosy</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>19</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>57</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>3</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>   
                                </div>
                            </div>                           
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/meena.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Dr. Meena K. Yadav</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutical Chemistry</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>13.4</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>15</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>5</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>5</span>
                                        </li>                               
                                    </ul>  
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/raziuddin.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Dr. Raziuddin Khan</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmacology</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>13.6</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>15</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>2</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>   
                                </div>
                            </div>                           
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/anand.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Anand Kumar Srivastava</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Associate Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutical Chemistry</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>13</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>3</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>  
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/deepak_chawrasia.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Deepak Chawrasia</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Associate Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Pharmaceutical Chemistry</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>10</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>1</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>   
                                </div>
                            </div>                          
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/jitendra.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Jitendra Pratap Singh</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Associate Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutics</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>9.5</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>2</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>2</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>    
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/rishabh.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Rishabh Maurya</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Associate Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutics</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>7.8</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>4</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>4</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>     
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/tanushree.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Ms. Tanushree Agnihotri</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Associate Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutics</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>7</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>0</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>1</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>   
                                </div>
                            </div>                           
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/shafkeen.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mrs. Shafkeen Siddiqui</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Associate Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmacology</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>5.6</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>3</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>   
                                </div>
                            </div>                           
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/yusuf.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Mohd Yusuf</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Assistant Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmacology</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>4.5</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>3</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>   
                                </div>
                            </div>                            
                        </div>
                    </div>

                    {/* <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/tanushree.png" alt="" />
                            </div>
                            <div class="course-main-content">
                                <div class="courses-desc last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Ms. Tanushree Agnihotri</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28">Associate Professor Department of Pharmaceutics</p>
                                </div>
                            </div>
                            <div class="card-footer media bg-white align-items-center justify-content-between">
                                <ul class="list-unstyled mb-0">
                                    <li class="mb-1">
                                        Total Experience (years) : <span>7</span>
                                    </li>   
                                    <li class="mb-1">
                                        No. of Publications : <span>0</span>
                                    </li> 
                                    <li class="mb-1">
                                        No. of Patents : <span>1</span>
                                    </li>   
                                    <li class="mb-1">
                                        Books/Chapters Published : <span>0</span>
                                    </li>                               
                                </ul>                                
                            </div>
                        </div>
                    </div> */}

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/satyam.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Satyam Shukla</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Assistant Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmacology</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>3.4</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>7</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>  
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/bablu.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Bablu Kumar</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Assistant Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Quality Assurance</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>3.4</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>1</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>     
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/roshan.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Roshan Kumar Dubey</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Assistant Professor</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutics</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>2.7</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>20</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>4</span>
                                        </li>                               
                                    </ul>   
                                </div>
                            </div>                           
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/srishtee.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mrs. Srishtee Srivastava</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Lecturer</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutics</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>1.4</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>1</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>0</span>
                                        </li>                               
                                    </ul>    
                                </div>
                            </div>                           
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 margin-30px-bottom">
                        <div class="courses-box">
                            <div class="courses-pic position-relative">
                                <img src="img/team/amit.png" alt="" />
                            </div>
                            <div class="pt-55">
                                <div class="courses-desc2 last-paragraph-no-margin">
                                    <div class="courses-title">
                                        <h4 class="font-size22 md-font-size20 sm-font-size18 margin-5px-bottom font-weight-500">Mr. Amit Kumar Maurya</h4>
                                    </div>                                    
                                    <p class="font-size15 xs-font-size14 line-height-28 mb-0">Lecturer</p>
                                    <p class="font-size15 xs-font-size14 line-height-28">Department of Pharmaceutical Chemistry</p>
                                    <ul class="list-unstyled mb-0">
                                        <li class="mb-1">
                                            Total Experience (years) : <span>1</span>
                                        </li>   
                                        <li class="mb-1">
                                            No. of Publications : <span>2</span>
                                        </li> 
                                        <li class="mb-1">
                                            No. of Patents : <span>0</span>
                                        </li>   
                                        <li class="mb-1">
                                            Books/Chapters Published : <span>2</span>
                                        </li>                               
                                    </ul>     
                                </div>
                            </div>                            
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default Faculty
