import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const AntiRaggingCommittee = () => {
  return (
    <>
      <Bredcrumb title="Anti Ragging Committee" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">                              
                              <div class="card">
                                <div class="card-body card-body-item">
                                  <div class="common-section-item">
                                    {/* <h2 class="common-section-title">Anti Ragging Committee</h2> */}
                                    <p class="mb-3">This is to inform to all the concerns that the following persons are nominated as members of Anti Ragging Committee for session 2023-24.</p>
                                    <table class="table table-striped">
                                    <thead>
                                      <tr>
                                          <th>S.N.</th>
                                          <th>Designation</th>
                                          <th>Name</th>
                                          <th>Contact No.</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>Directed by</td>
                                        <td>Dr. Zeashan Hussain</td>
                                        <td>9935844704</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Headed by</td>
                                        <td>Dr. Meena Yadav</td>
                                        <td>9919991177</td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Coordinator</td>
                                        <td>Mr. Rishabh Maurya</td>
                                        <td>7007357050</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Internal Committee </td>
                                        <td>Mr. Anand Km Srivastava</td>
                                        <td>9369229157</td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>Internal Committee</td>
                                        <td>Dr. Raziuddin Khan</td>
                                        <td>8090183694</td>
                                    </tr>
                                    <tr>
                                        <td>6.</td>
                                        <td>External Committee</td>
                                        <td>Mr. Jitendra Pratap SIngh</td>
                                        <td>8707380537</td>
                                    </tr>
                                    <tr>
                                        <td>7.</td>
                                        <td>External Committe</td>
                                        <td>Mr. Mohd Yusuf</td>
                                        <td>8543052518</td>
                                    </tr>
                                    <tr>
                                        <td>8.</td>
                                        <td>4th  Year Student Representative B.Pharm</td>
                                        <td>Anurag Kumar Mishra</td>
                                        <td>9838391379</td>
                                    </tr>
                                    <tr>
                                        <td>9.</td>
                                        <td>4th  Year Student Representative B.Pharm</td>
                                        <td>Rashid Khan</td>
                                        <td>7080926323</td>
                                    </tr>
                                    <tr>
                                        <td>10.</td>
                                        <td>3rd Year Student Representative B.Pharm</td>
                                        <td>Shiva Singh</td>
                                        <td>9120785252</td>
                                    </tr>
                                    <tr>
                                        <td>11.</td>
                                        <td>3rd Year Student Representative B.Pharm</td>
                                        <td>Ankush Kumar Thakur</td>
                                        <td>9807686129</td>
                                    </tr>
                                    <tr>
                                        <td>12.</td>
                                        <td>2nd Year Student Representative B.Pharm</td>
                                        <td>Tanmay Viswah</td>
                                        <td>9336177034</td>
                                    </tr>
                                    <tr>
                                        <td>12.</td>
                                        <td>2nd Year Student Representative B.Pharm</td>
                                        <td>Sumit Mishra</td>
                                        <td>8881482818</td>
                                    </tr> 
                                    <tr>
                                        <td>13.</td>
                                        <td>1st Year Student Representative B.Pharm</td>
                                        <td>Vikash Kumar</td>
                                        <td>9006959138</td>
                                    </tr> 
                                    <tr>
                                        <td>14.</td>
                                        <td>1st Year Student Representative B.Pharm</td>
                                        <td>Shubham Yadav</td>
                                        <td>7081350554</td>
                                    </tr> 
                                    <tr>
                                        <td>15.</td>
                                        <td>1st Year Student Representative D.Pharm</td>
                                        <td>Sachin Gautam</td>
                                        <td>7754012126</td>
                                    </tr> 
                                    <tr>
                                        <td>16.</td>
                                        <td>1st Year Student Representative D.Pharm</td>
                                        <td>Deepak Kumar</td>
                                        <td>6393899627</td>
                                    </tr> 
                                    <tr>
                                        <td>17.</td>
                                        <td>2nd Year Student Representative D.Pharm</td>
                                        <td>Zaiduddin </td>
                                        <td>6392496050</td>
                                    </tr> 
                                    <tr>
                                        <td>18.</td>
                                        <td>2nd Year Student Representative D.Pharm</td>
                                        <td>Rahul Maurya </td>
                                        <td>6386952447</td>
                                    </tr> 
                                    </tbody>
                                    </table>
                                    {/* <p className='mb-2 text-right'><h5>Dr. Zeashan Hussain</h5></p>
                                      <p className='mb-0 text-right'>Director</p> */}
                                  </div>                                 
                                </div>
                              </div>
                              {/* <div class="common-section-img">
                                  <img src="img/content/640x360.png" alt="" class="border-radius-6" />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Committees</h4>
                                <ul class="sidebar-links">
                                  <li><Link to={'/AntiDiscriminationCommittee'}>Anti Discrimination Committee</Link></li>
                                  <li><Link to={'/AntiRaggingCommittee'}>Anti Ragging Committee</Link></li>
                                  <li><Link to={'/SCSTCommittee'}>SC/ST Standing Committee</Link></li>
                                  <li><Link to={'/GenderSensitizationCommittee'}>Gender Sensitization Committee</Link></li>
                                  <li><Link to={'/StudentGrievanceRedressalCommittee'}>Student Grievance Redressal Committee</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default AntiRaggingCommittee
