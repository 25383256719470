import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const HumanAnatomyPathology = () => {
  return (
    <>
      <Bredcrumb title="Human Anatomy and Pathology Lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>Every human being, tissues, human body parts and the organ systems are made up of cells- the fundamental unit of life. Anatomy is the science of understanding the structure and the parts of living organisms. Physiology, on the other hand, deals with the internal mechanisms and the processes that work towards sustaining life.</p> 
                                <p>These can include biochemical and physical interactions between various factors and components in our body. With the progress of evolution, organisms began to exhibit advanced characteristics and features that enabled them to be more efficient and thrive in their respective environment.</p>
                                <p>The human structure can be described as bipedal, with hair covering the body, presence of mammary glands and a set of extremely well-developed sense organs. With respect to human body anatomy, we have a specialized circulatory system that enables the efficient transport of materials and nutrients within the body.</p>                                                            
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_01.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_02.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_03.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_04.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_05.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_06.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_07.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_08.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_09.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/human_anatomy_10.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default HumanAnatomyPathology
