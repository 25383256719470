import React from 'react'
import { Link } from 'react-router-dom'

const FacilitiesSidebar = () => {
  return (
    <>
        <h4 class="title-bg title-bg-default">Facilities</h4>
        <ul class="sidebar-links">
            <li><Link to={'/PharmaceuticsLab'}>Pharmaceutics Lab</Link></li>
            <li><Link to={'/Pharmacologylab'}>Pharmacology Lab</Link></li>
            <li><Link to={'/Pharmacognosylab'}>Pharmacognosy Lab</Link></li>
            <li><Link to={'/PharmaceuticalAnalysis'}>Pharmaceutical Analysis Lab</Link></li>		
            <li><Link to={'/PharmaceuticalChemistry'}>Pharmaceutical Chemistry Lab</Link></li>
            <li><Link to={'/HumanAnatomyPathology'}>Human Anatomy and Pathology Lab</Link></li>
            <li><Link to={'/MicrobiologyLab'}>Microbiology Lab</Link></li>
            <li><Link to={'/ComputerLab'}>Computer Lab</Link></li>	
            <li><Link to={'/HerbalGarden'}>Herbal Garden</Link></li>
            <li><Link to={'/MachineRoom'}>Machine Room</Link></li>
            <li><Link to={'/CentralInstrumentRoom'}>Central Instrument Room</Link></li>
            <li><Link to={'/Library'}>Library</Link></li>	
            <li><Link to={'/Auditorium'}>Auditorium</Link></li>
            <li><Link to={'/Cafeteria'}>Cafeteria</Link></li>
            <li><Link to={'/Transport'}>Transport</Link></li>
            <li><Link to={'/Hostel'}>Hostel</Link></li>	
            <li><Link to={'/WiFiCampus'}>Wi-Fi Campus</Link></li>	
            <li><Link to={'/Sports'}>Sports</Link></li>	
            <li><Link to={'/EmergencyMedicare'}>Emergency Medicare</Link></li>
            <li><Link to={'/Security'}>Campus Security</Link></li>
            <li><Link to={'/BankingFacility'}>Banking Facility</Link></li>
        </ul>
    </>
  )
}

export default FacilitiesSidebar
