import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const FeeStructure = () => {
  return (
    <>
      <Bredcrumb title="Fee Structure" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb-1-9 mb-lg-0">
                        <p>Fee Structure of different courses are available at the reception of the institute. For any query please call us at +91 9935844704, +91 9415929663, +91 9305864359</p>
                         <p>The Institute fee & Hostel fee can also made by NEFT/RTGS.</p>                         
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default FeeStructure
