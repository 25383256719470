import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const AntiDiscriminationCommittee = (props) => {
  return (
    <>
      <Bredcrumb title="Anti Discrimination Committee" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                              
                              <div class="card">
                                <div class="card-body card-body-item">
                                  <div class="common-section-item">
                                    {/* <h2 class="common-section-title">Stablishment of Committee for Anti Discrimination Cell</h2> */}
                                    <p class="mb-0">As per the Anti Discrimination Cell is constituted to safeguard the interest of students without any prejudice to their caste, creed, religion, language, ethnicity, Gender, disability and to eliminate discrimination of harassment against any student in Hindustan Institute of Technology and science by providing preventive measures facilitate punishment for those who indulge in any form of discrimination to promote equality among the students.</p>
                                    <p>The following persons are nominated as members of ANTI DISCRIMINATION CELL for session 2023-24.</p>
                                    <table class="table table-striped">
                                    <thead>
                                      <tr>
                                          <th>S.N.</th>
                                          <th>Designation</th>
                                          <th>Name</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>Convener</td>
                                        <td>Dr. Zeashan Hussain</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Member</td>
                                        <td>Dr. Meena K Yadav</td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Member</td>
                                        <td>Dr. Raziuddin Khan</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Member</td>
                                        <td>Mr. Anand Kumar Srivastava</td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>Member</td>
                                        <td>Mr. Jitendra Pratap Singh</td>
                                    </tr>
                                    <tr>
                                        <td>6.</td>
                                        <td>Member</td>
                                        <td>Ms. Shafkeen Siddiqui</td>
                                    </tr>
                                    </tbody>
                                    </table>
                                      {/* <p className='mb-2 text-right'><h5>Dr. Zeashan Hussain</h5></p>
                                      <p className='mb-0 text-right'>Director</p> */}
                                  </div>                                 
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Committees</h4>
                                <ul class="sidebar-links">
                                  <li><Link to={'/AntiDiscriminationCommittee'}>Anti Discrimination Committee</Link></li>
                                  <li><Link to={'/AntiRaggingCommittee'}>Anti Ragging Committee</Link></li>
                                  <li><Link to={'/SCSTCommittee'}>SC/ST Standing Committee</Link></li>
                                  <li><Link to={'/GenderSensitizationCommittee'}>Gender Sensitization Committee</Link></li>
                                  <li><Link to={'/StudentGrievanceRedressalCommittee'}>Student Grievance Redressal Committee</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default AntiDiscriminationCommittee
