import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import Navbar from './Common/Navbar';
import Footer from './Common/Footer';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Inquiry from './Common/Inquiry';
import Admission from './Pages/Admission';
import StudentOnlineServices from './Pages/StudentOnlineServices';
import VisionMission from './Pages/VisionMission';
import DirectorMessage from './Pages/DirectorMessage';
import ChairpersonMessage from './Pages/ChairpersonMessage';
import PrincipalMessage from './Pages/PrincipalMessage';
import Courses from './Pages/Courses';
import AirConditionedClassrooms from './Pages/AirConditionedClassrooms';
import ComputerLab from './Pages/ComputerLab';
import Library from './Pages/Library';
import Cafeteria from './Pages/Cafeteria';
import Transport from './Pages/Transport';
import Hostel from './Pages/Hostel';
import EmergencyMedicare from './Pages/EmergencyMedicare';
import Auditorium from './Pages/Auditorium';
import Security from './Pages/Security';
import WiFiCampus from './Pages/WiFiCampus';
import Sports from './Pages/Sports';
import HerbalGarden from './Pages/HerbalGarden';
import AntiDiscriminationCommittee from './Pages/AntiDiscriminationCommittee';
import PlacementsData from './Pages/PlacementsData';
import TrainingPlacements from './Pages/TrainingPlacements';
import OurRecruiter from './Pages/OurRecruiter';
import IndustrialVisit from './Pages/IndustrialVisit';
import AwardsAchievements from './Pages/AwardsAchievements';
import Events from './Pages/Events';
import Photo from './Pages/Photo';
import Video from './Pages/Video';
import AntiRaggingCommittee from './Pages/AntiRaggingCommittee';
import SCSTCommittee from './Pages/SCSTCommittee';
import GenderSensitizationCommittee from './Pages/GenderSensitizationCommittee';
import StudentGrievanceRedressalCommittee from './Pages/StudentGrievanceRedressalCommittee';
import PharmaceuticsLab from './Pages/PharmaceuticsLab';
import Pharmacologylab from './Pages/Pharmacologylab';
import Pharmacognosylab from './Pages/Pharmacognosylab';
import PharmaceuticalAnalysis from './Pages/PharmaceuticalAnalysis';
import PharmaceuticalChemistry from './Pages/PharmaceuticalChemistry';
import HumanAnatomyPathology from './Pages/HumanAnatomyPathology';
import MicrobiologyLab from './Pages/MicrobiologyLab';
import MachineRoom from './Pages/MachineRoom';
import CentralInstrumentRoom from './Pages/CentralInstrumentRoom';
import BankingFacility from './Pages/BankingFacility';
import PlacementRecord2023 from './Pages/PlacementRecord2023';
import PlacementRecord202324 from './Pages/PlacementRecord202324';
import PlacementDetail2022 from './Pages/PlacementDetail2022';
import PlacementDetail2021 from './Pages/PlacementDetail2021';
import PlacmentGraph from './Pages/PlacmentGraph';
import Careers from './Pages/Careers';
import Faculty from './Pages/Faculty';
import Alumni from './Pages/Alumni';
import FeeStructure from './Pages/FeeStructure';
import Sitemap from './Pages/Sitemap';
import LatestUpdates from './Pages/LatestUpdates';
import FresherParty from './Pages/FresherParty';
import FieldVisitCimap from './Pages/FieldVisitCimap';
import Plantation from './Pages/Plantation';
import FieldVisitNbri from './Pages/FieldVisitNbri';
import VisitHospital from './Pages/VisitHospital';
import SportsMeet from './Pages/SportsMeet';
import PlacementRecord201617 from './Pages/PlacementRecord201617';

function App() {
  

  return (
    <div className="main-wrapper">   
      <BrowserRouter>
        <Inquiry />
        <Navbar />
        <Routes>            
            <Route path="/" element={<Home />}></Route>  
            <Route path="/About" element={<About />}></Route>     
            <Route path="/Contact" element={<Contact />}></Route>    
            <Route path='/Admission' element={<Admission />}></Route>
            <Route path='/StudentOnlineServices' element={<StudentOnlineServices />}></Route>
            <Route path='/VisionMission' element={<VisionMission />}></Route>
            <Route path='/DirectorMessage' element={<DirectorMessage />}></Route>
            <Route path='/ChairpersonMessage' element={<ChairpersonMessage />}></Route>
            <Route path='/PrincipalMessage' element={<PrincipalMessage />}></Route>
            <Route path='/Courses' element={<Courses />}></Route>
            <Route path='/AirConditionedClassrooms' element={<AirConditionedClassrooms />}></Route>
            <Route path='/ComputerLab' element={<ComputerLab />}></Route>
            <Route path='/Library' element={<Library />}></Route>
            <Route path='/Cafeteria' element={<Cafeteria />}></Route>
            <Route path='/Transport' element={<Transport />}></Route>
            <Route path='/Hostel' element={<Hostel />}></Route>
            <Route path='/EmergencyMedicare' element={<EmergencyMedicare />}></Route>
            <Route path='/Auditorium' element={<Auditorium />} ></Route>
            <Route path='/Security' element={<Security />}></Route>
            <Route path='/WiFiCampus' element={<WiFiCampus />}></Route>
            <Route path='/Sports' element={<Sports />}></Route>
            <Route path='/HerbalGarden' element={<HerbalGarden />}></Route>
            <Route path='/AntiDiscriminationCommittee' element={<AntiDiscriminationCommittee />}></Route>
            <Route path='/PlacementsData' element={<PlacementsData />}></Route>
            <Route path='/TrainingPlacements' element={<TrainingPlacements />}></Route>
            <Route path='/OurRecruiter' element={<OurRecruiter />}></Route>
            <Route path='/IndustrialVisit' element={<IndustrialVisit />}></Route>
            <Route path='/AwardsAchievements' element={<AwardsAchievements />}></Route>
            <Route path='/Events' element={<Events />}></Route>
            <Route path='/Photo' element={<Photo />}></Route>
            <Route path='/Video' element={<Video />}></Route>
            <Route path='/AntiRaggingCommittee' element={<AntiRaggingCommittee />}></Route>
            <Route path='/SCSTCommittee' element={<SCSTCommittee />}></Route>
            <Route path='/GenderSensitizationCommittee' element={<GenderSensitizationCommittee />}></Route>
            <Route path='/StudentGrievanceRedressalCommittee' element={<StudentGrievanceRedressalCommittee />}></Route>
            <Route path='/PharmaceuticsLab' element={<PharmaceuticsLab />}></Route>
            <Route path='/Pharmacologylab' element={<Pharmacologylab />}></Route>
            <Route path='/Pharmacognosylab' element={<Pharmacognosylab />}></Route>
            <Route path='/PharmaceuticalAnalysis' element={<PharmaceuticalAnalysis />}></Route>
            <Route path='/PharmaceuticalChemistry' element={<PharmaceuticalChemistry />}></Route>
            <Route path='/HumanAnatomyPathology' element={<HumanAnatomyPathology />}></Route>
            <Route path='/MicrobiologyLab' element={<MicrobiologyLab />}></Route>
            <Route path='/MachineRoom' element={<MachineRoom />}></Route>
            <Route path='/CentralInstrumentRoom' element={<CentralInstrumentRoom />}></Route>
            <Route path='/BankingFacility' element={<BankingFacility />}></Route>
            <Route path='/PlacementRecord2023' element={<PlacementRecord2023 />}></Route>  
            <Route path='/PlacementRecord202324' element={<PlacementRecord202324 />}></Route>      
            <Route path='/PlacementDetail2022' element={<PlacementDetail2022 />}></Route>   
            <Route path='/PlacementDetail2021' element={<PlacementDetail2021 />}></Route>
            <Route path='/PlacmentGraph' element={<PlacmentGraph />}></Route>
            <Route path='/Careers' element={<Careers />}></Route>
            <Route path='/Faculty' element={<Faculty />}></Route>
            <Route path='/Alumni' element={<Alumni />}></Route>
            <Route path='/FeeStructure' element={<FeeStructure />}></Route>
            <Route path='/Sitemap' element={<Sitemap />}></Route>
            <Route path='/LatestUpdates' element={<LatestUpdates />}></Route>
            <Route path='/FresherParty' element={<FresherParty />}></Route>
            <Route path='/FieldVisitCimap' element={<FieldVisitCimap />}></Route>
            <Route path='/Plantation' element={<Plantation />}></Route>
            <Route path='/FieldVisitNbri' element={<FieldVisitNbri />}></Route>
            <Route path='/VisitHospital' element={<VisitHospital />}></Route>
            <Route path='/SportsMeet' element={<SportsMeet />}></Route>
            <Route path='/PlacementRecord201617' element={<PlacementRecord201617 />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
