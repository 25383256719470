import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'

const Video = () => {
  return (
    <>
      <Bredcrumb title="Video" />
      <section className="facility-box mt-4">
        <div className="container">
          {/* <div className="row">
            <div className="col-8 mx-auto center-col margin-50px-bottom sm-margin-40px-bottom text-center">
              <h3 className="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 section-title">Video</h3>
              <div className="title-border"><span className="lg"></span><span className="md"></span><span className="sm"></span></div>
            </div>
          </div> */}
          <div className="row mb-4">
				        <div className="col-md-12">
					          <div className="gallery text-center row">
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe  src="https://www.youtube.com/embed/5vyA1JwTJGI?si=aaUsJDYznc8H5ZTF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>    
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe  src="https://www.youtube.com/embed/ucMSnAVSFMs?si=GdFfKpYjn3FkuSEF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/TdQpyc_DEYQ?si=FXMq24afRamzQOu6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/-nPhlQPIQjg?si=OKIfqV3_O1c1NMFh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>    
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/-bHM0dkCkLk?si=wK6tscayZQaHSvVV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/jwKLW6rS52I?si=rRj5-Wnp-xUY64vw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>    
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/rGFWHZzomQ8?si=pD_FT1JdgTMKHbYA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/u70J8ybemYM?si=dEN-49g1AaVhhIyF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>       
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/JhixbxYm2jg?si=C7uULNeGL4DAlYQj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>     
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/ObVAfin96s4?si=VOOl3IUQ9AspZoxx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/MQXdr5cuuPo?si=fU-fgfCiojc2CgIF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/SNPSEHwEDv8?si=_JDzLyIPD7gBj9jm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/5vyA1JwTJGI?si=aJP4MGo84sEIVmz_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/_ruGrnYKHyQ?si=uRAvTiifGxsUHW5A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/ANCRVcJJOaY?si=EHwhWOxwZc_iQGzd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/xNh9ok8CJGU?si=67A_Y7juOV9QLo_w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/npLVLnkv8J4?si=XxdOTy2vzAS2y55D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/npLVLnkv8J4?si=VOX1DSY5iOZTHWVB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/J6JHYCqJVPo?si=DN5eO0skRudNgZdz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/2jUwFLGrOO0?si=kG6A_grl6DlfD4FG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/v7lo2VYL5YI?si=8r-LDNK3SkrHtiCr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/J6JHYCqJVPo?si=xzZnKxasmZJfrWoD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/2jUwFLGrOO0?si=Y76MKpDeRGA5OPuS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/2jUwFLGrOO0?si=eEoc177FUXwxFiJy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/v7lo2VYL5YI?si=9-ue8jcqvqe6fRBo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/DM8HYBEzpqU?si=hdBH7zwFL3rT2JRf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/Azms0z0GECs?si=h11n_ckzOHemj14j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/thzk5NBhpbY?si=b9V6AUaxs9595BqY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>     
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/nDxWLEzKmQA?si=FxWvuAu9YPnVjbmi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/_sRTNvZv8wo?si=8trR8o8DWlI4D1Nt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/q10uwJQUVSM?si=_g8J_Z24jjCm7052" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/Keh_QhRj9UQ?si=QAqsbNpPxPNERl9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>      
                                <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <iframe src="https://www.youtube.com/embed/5oiakLkjbs0?si=dV1ZqieTgeUa8wUM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>                 
                            </div>
                    </div>
                    </div>	           
                </div>
        </section>
    </>
  )
}

export default Video
