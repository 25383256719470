import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'

const Courses = () => {
  return (
    <>
      <Bredcrumb title="Courses" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row">              
                                <div className="col-md-4">
                                    <div class="service-3 hover-service" data-aos="fade-up">
                                        <img src="img/content/m-pharm01.png" alt="..." />
                                        <div class="service-brief">
                                            <div class="elementor-flip-box-layer-overlay">
                                                <h4>M. PHARM. (Master of Pharmacy)</h4>
                                                <p className='h-auto'>2 Years full-time post graduate course in Pharmacology and Pharmaceutics.</p>
                                                <p className="fw-bold">Intake : Pharmaceutics - 09 seats</p>						
                                                <p className="fw-bold">Intake : Pharmacology - 09 seats</p>
                                             </div>
                                        </div>
                                        <div class="elementor-flip-box-layer-overlay-1">
                                            <h4>M. PHARM. (Master of Pharmacy)</h4>                                            
                                        </div>
                                    </div>						
                                </div>	   

                                <div className="col-md-4">
                                    <div class="service-3 hover-service" data-aos="fade-up">
                                        <img src="img/content/b-pharm01.png" alt="..." />
                                        <div class="service-brief">
                                            <div class="elementor-flip-box-layer-overlay">
                                                <h4>B. PHARM. (Bachelor of Pharmacy)</h4>
                                                <p>4 Years full-time degree course in Pharmacy.</p>
                                                <p className="fw-bold">Intake: 100 Student</p>
                                            </div>
                                        </div>
                                        <div class="elementor-flip-box-layer-overlay-1">
                                            <h4>B. PHARM. (Bachelor of Pharmacy)</h4>                                            
                                        </div>
                                    </div>						
                                </div>	 
                                

                                <div className="col-md-4">
                                    <div class="service-3 hover-service" data-aos="fade-up">
                                        <img src="img/content/d-pharm01.png" alt="..." />
                                        <div class="service-brief">
                                            <div class="elementor-flip-box-layer-overlay">
                                                <h4>D. PHARM. (Diploma in Pharmacy)</h4>
                                                <p>2 Years Full-Time Diploma Course in Pharmacy.</p>
                                                <p className="fw-bold">Intake: 60 Student</p>
                                             </div>
                                        </div>
                                        <div class="elementor-flip-box-layer-overlay-1">
                                            <h4>D. PHARM. (Diploma in Pharmacy)</h4>
                                        </div>
                                    </div>						
                                </div>	 

                                {/* <div className="col-md-4">	
                                    <div class="courses-pic2 position-relative">
                                        <img src="img/content/m-pharm.png" className='thumb-img_new' alt="" />
                                    </div>                               
                                    <div className="sec_top_new">
                                        
                                        <h5>M. PHARM. (Master of Pharmacy)</h5>
                                        <p>2 Years full-time post graduate course in Pharmacology and Pharmaceutics.</p>
                                        <p className="fw-bold">Intake : Pharmaceutics - 09 seats</p>						
                                        <p className="fw-bold">Intake : Pharmacology - 09 seats</p>
                                    </div>                              
                                </div>        */}
                                {/* <div className="col-md-4">	 
                                    <div class="courses-pic2 position-relative">
                                        <img src="img/content/b-pharm.png" className='thumb-img_new' alt="" />
                                    </div>                              
                                    <div className="sec_top_new">
                                        <h5>B. PHARM. (Bachelor of Pharmacy)</h5>
                                        <p>4 Years full-time degree course in Pharmacy.</p>
                                        <p className="fw-bold">Intake: 100 Student</p>
                                    </div>                              
                                </div>    
                                <div className="col-md-4">	
                                    <div class="courses-pic2 position-relative">
                                        <img src="img/content/d-pharm.png" className='thumb-img_new' alt="" />
                                    </div>                               
                                    <div className="sec_top_new">
                                        <h5>D. PHARM. (Diploma in Pharmacy)</h5>
                                        <p>2 Years full-time diploma course in Pharmacy.</p>
                                        <p className="fw-bold">Intake: 60 Student</p>
                                    </div>                              
                                </div>    */}
                            </div>
                        </div>                    
                    </div>
            </div>
        </section>

    
    </>
  )
}

export default Courses
