import React, { useState } from 'react'
const Inquiry = () => {
    const [clicked, setClicked]= useState(false)
    let showForm=()=>{
      setClicked(!clicked)
      console.log(clicked)
    }
    let closeForm=()=>{
      setClicked(false)
    }
  return (
    <div>
        <div id="wpcs_tab_17489" className="wpcs_tab"><span href='#' onClick={showForm} className='text-white'>Admission Enquiry</span></div>
        {clicked && <div id="copy-code" className="mfp-hide" style={{display:'block'}}>        
            <div className="page-right-sidebar-inner">      
                <button className="btn-close" onClick={closeForm}></button>
                <div className="right-sidebar-content">
                  <div className="row justify-content-end mt-3">
                <div className="col-lg-3 mb-2-2 mb-lg-0">
                  <div className="p-4 box-shadows h-100">
                    <h4 className="mb-4">Admission Enquiry</h4>
                    <form className="contact quform">
                                <div className="quform-elements">
                                    <div className="row">
                                        <div className="col-md-12  mb-3">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-user'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Name" aria-label="Input group example" aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="col-md-12  mb-3">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-envelope'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Email" aria-label="Input group example" aria-describedby="basic-addon1" />
                                            </div>
                                        </div>                                       
                                        <div className="col-md-12  mb-3">                                           
                                            <div class="input-group quform-element form-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i className='fa fa-phone'></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Contact No." aria-label="Input group example" aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="col-md-12  mb-3">                                           
                                            <div class="input-group quform-element form-group">                                                
                                                <select className='form-selct'>
                                                    <option>--Select Course --</option>
                                                    <option>M. Pharm.</option>
                                                    <option>B. Pharm.</option>
                                                    <option>D. Pharm.</option>                                                    
                                                </select>
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-12 mb-3">
                                            <div className="quform-element form-group">                                              
                                                <div className="quform-input">
                                                    <textarea className="form-control" rows="3" placeholder="Message"></textarea>
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div className="col-md-12">
                                            <div className="quform-submit-inner">
                                                <button className="butn fill"><span className="label">Submit</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i></button>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </form>
                  </div>
                </div>                   
              </div>
                </div>
            </div>
        </div>}
    </div>
  )
}

export default Inquiry
