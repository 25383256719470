import React from 'react'
import { } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const Pharmacologylab = () => {
  return (
    <>
      <Bredcrumb title="Pharmacology lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>The department is involved in screening of natural and synthetic drugs for their pharmacological activities using in vitro and in vivo models involving preclinical and toxicological studies. Knowledge on experimental skill is imparted to students through computer aided visuals. Pharmacology provides the platform for establishing the therapeutic potential for new agents and formulations. The department objectives are designed to make the students familiar with drugs, the action of drugs, use of drugs, doses and toxic symptoms and to promote research activities.</p>
                                <p>The department has a well-maintained CPCSEA approved Animal House (Research for Commercial Purpose). The institution has an Institutional Animal Ethical Committee (IAEC), which comprises of experts and eminent personalities from various fields. The IAEC conducts its meetings at regular intervals.o</p>                                                      
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacology-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacology-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacology-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacology-04.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacology-05.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacology-06.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                                <div class="card-body">
                                    <FacilitiesSidebar />
                                </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Pharmacologylab
