import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const AwardsAchievements = () => {
  return (
    <>
       <Bredcrumb title="Awards & Achievements" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 mb-1-9 mb-lg-0 mx-auto">
                        <div className="gallary-block">
                            <div className="position-relative">
                                <img src="img/content/010424.jpeg" className='img-thumb' alt="..." />                                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AwardsAchievements
