import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'

const PlacmentGraph = () => {
  return (
    <>
    <Bredcrumb title="Placment Graph" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8">
                       <p className='pb-3'><img src="img/graph01.png" className='graph' alt="..." /></p>
                        <p><img src="img/graph02.png" className='graph' alt="..." /></p>
                    </div>
                    <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Placement</h4>
                                <ul class="sidebar-links">
                                    <li><Link to={'/PlacementRecord202324'}>Placement Record 2023-24</Link></li>
                                    <li><Link to={'/PlacementRecord2023'}>Placement Record 2022-23</Link></li>                                    
                                    <li><Link to={'/PlacementRecord201617'}>Placement Record 2016-17</Link></li>                                   
                                    <li><Link to={'/PlacmentGraph'}>Placment Graph</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                    </div>
              </div>
          </div>
      </section>
  </>
  )
}

export default PlacmentGraph
