import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const Alumni = () => {
  return (
    <>
      <Bredcrumb title="Alumni Registration" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 mb-1-9 mb-lg-0 mx-auto">
                        <form class="quform" action="" method="post">
                            <div class="quform-elements">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input id="name" class="form-control" type="text" name="name" placeholder="Name" />
                                            </div>
                                        </div>
                                    </div>                                     
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input id="email" class="form-control" type="text" name="email" placeholder="Date of Birth" />
                                            </div>
                                        </div>
                                    </div>    
                                    <div className="col-md-6  mb-3">                                           
                                        <div class="input-group quform-element form-group">                                                
                                            <select className='form-selct'>
                                                <option>--Gender --</option>
                                                <option>Male</option>
                                                <option>Female</option>                                                    
                                            </select>
                                        </div>
                                    </div>    
                                       
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input id="phone" class="form-control" type="text" name="phone" placeholder="Father's Name" />
                                            </div>
                                        </div>
                                    </div>    
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <textarea id="message" class="form-control" name="message" rows="2" placeholder="Address:"></textarea>
                                            </div>
                                        </div>
                                    </div>   
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input  class="form-control" type="text" name="" placeholder="Mobile No." />
                                            </div>
                                        </div>
                                    </div>      
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input  class="form-control" type="text" name="" placeholder="Alternate No." />
                                            </div>
                                        </div>
                                    </div>        
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input  class="form-control" type="text" name="" placeholder="Email" />
                                            </div>
                                        </div>
                                    </div>        
                                                   
                                    <div className="col-md-6  mb-3">                                           
                                        <div class="input-group quform-element form-group">                                                
                                            <select className='form-selct'>
                                                <option>--Select Course --</option>
                                                <option>M. Pharm.</option>
                                                <option>B. Pharm.</option>
                                                <option>D. Pharm.</option>                                                    
                                            </select>
                                        </div>
                                    </div>                                   

                                     <div className="col-md-6  mb-3">                                           
                                        <div class="input-group quform-element form-group">                                                
                                            <select className='form-selct'>
                                                <option>--Batch --</option>
                                                <option>2023</option>
                                                <option>2022</option>
                                                <option>2021</option>                                                    
                                            </select>
                                        </div>
                                    </div>                                   

                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input  class="form-control" type="text" name="" placeholder="Profession" />
                                            </div>
                                        </div>
                                    </div>                                       
                                    <div class="col-md-6">
                                        <div class="quform-element form-group mb-3">
                                            <div class="quform-input">
                                                <input  class="form-control" type="file" name="" placeholder="File Uploader" />
                                            </div>
                                        </div>
                                    </div>                                      
                                    <div className="col-md-6">
                                        <div className="quform-submit-inner">
                                            <button className="butn fill"><span className="label">Submit</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i></button>
                                        </div>                                            
                                    </div>
                                </div>
                            </div>
                        </form>   
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Alumni
