import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer class="border-top padding-70px-top">
		<div class="container">
			<div class="row">				
				<div class="col-lg-8 col-md-8 sm-margin-30px-bottom">
					<div class="padding-30px-left md-padding-25px-left sm-no-padding-left">
						<div class="footer-title-style3 margin-15px-bottom">
							<h3>Quick Links</h3>
							<div class="title-border"><span class="lg"></span><span class="md"></span><span class="sm"></span></div>
						</div>
						<div class="row">
							<div class="col-sm-4 no-padding-right xs-padding-15px-right">
								<ul class="footer-list2 xs-margin-8px-bottom">
									<li><Link to={'/About'}>About Us</Link></li>
									<li><Link to={'/Careers'}>Careers</Link></li>
									<li><Link to={'/Alumni'}>Alumni</Link></li>
									<li><Link to={'/Faculty'}>Faculty</Link></li>									
								</ul>
							</div>
							<div class="col-sm-4 no-padding-right xs-padding-15px-right">
								<ul class="footer-list2">
									<li><Link to={'/AntiDiscriminationCommittee'}>Committees</Link></li>
									<li><Link to={'/Courses'}>Courses</Link></li>
									<li><Link to={'/img/pdf/Admission_Form.pdf'} target='blank'>Admission Form</Link></li>									
									<li><Link to={'/img/pdf/brochure.pdf'} target='blank'>Brochure</Link></li>
								</ul>
							</div>
							<div class="col-sm-4 no-padding-right xs-padding-15px-right">
								<ul class="footer-list2">
									<li><Link to={'/FeeStructure'}>Fee Structure</Link></li>
									<li><Link to={'/LatestUpdates'}>Latest Updates</Link></li>
									<li><Link to={'/Sitemap'}>Sitemap </Link></li>									
									<li><Link to={'/Contact'}>Contact Us</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-4">
					<div class="sm-no-padding-left">
						<div class="footer-title-style3 margin-15px-bottom">
							<h3>Get in Touch</h3>
							<div class="title-border"><span class="lg"></span><span class="md"></span><span class="sm"></span></div>
						</div>
						<p class="no-margin-bottom text-white pb-2"><i class="fas fa-map-marker-alt"></i> Junabganj, Kanpur Road, Lucknow 227101.</p>
						<p className='pb-2'><i class="fas fa-mobile-alt"></i> +91 9935844704, +91 9415929663, +91 9305864359</p>
						<p className='pb-2'><Link href="mailto:director.mgip@gmail.com" Class="text-white"><i class="fas fa-envelope"></i> director.mgip@gmail.com</Link></p>
						<div class="margin-20px-top footer-social-icons2">
						<ul>
							<li><Link to={'https://www.facebook.com/mgip391?ref=embed_page'} target='blank'><i class="fab fa-facebook-f"></i></Link></li>
							<li><Link to={'/'}><svg xmlns="http://www.w3.org/2000/svg" className='tw_svg' viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></Link></li>
							<li><Link to={'https://www.instagram.com/mgipofficial?igsh=MWdua3J1cnZwcnF0cA=='} target='blank'><i class="fab fa-instagram"></i></Link></li>
							<li><Link to={'https://www.linkedin.com/school/mahatma-gandhi-institute-of-pharmacy-lucknow/'} target='blank'><i class="fab fa-linkedin-in"></i></Link></li>
							<li><Link to={'https://www.youtube.com/@mahatmagandhiinstituteofph'} target='blank'><i class="fab fa-youtube"></i></Link></li>
						</ul>
					</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bar xs-font-size13 border-top border-color-light-gray">
			<div class="container">
				<div class="row">
					<div class="col-md-6 xs-margin-5px-bottom text-white sm-font-size13 px-5">
						<p className='text-left xs-text-left'>© MGIP Lucknow 2024. All Rights Reserved.</p>
					</div>
					<div class="col-md-6 sm-font-size13">
						<p className='text-right-text xs-text-right text-white'>Powered by <Link to={'https://adventusindia.com'} target='blank' className='text-white'>Adventus</Link></p>
					</div>
				</div>
			</div>
		</div>
	</footer>
  )
}

export default Footer
