import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const AirConditionedClassrooms = () => {
  return (
    <>
      <Bredcrumb title="Air Conditioned Classrooms" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">                                    
                                <p>The class rooms are subject specific and fully air conditioned along with the overhead and LCD projector facility. The institution also has tutorial rooms for the students who require extra lectures.</p>                                                         
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/class_room-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/class_room-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/class_room.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Facilities</h4>
                                <ul class="sidebar-links">
                                    <li><Link to={'/PharmaceuticsLab'}>Pharmaceutics Lab</Link></li>
                                    <li><Link to={'/Pharmacologylab'}>Pharmacology Lab</Link></li>
                                    <li><Link to={'/Pharmacognosylab'}>Pharmacognosy Lab</Link></li>
                                    <li><Link to={'/PharmaceuticalAnalysis'}>Pharmaceutical Analysis Lab</Link></li>		
                                    <li><Link to={'/PharmaceuticalChemistry'}>Pharmaceutical Chemistry Lab</Link></li>
                                    <li><Link to={'/HumanAnatomyPathology'}>Human Anatomy and Pathology Lab</Link></li>
                                    <li><Link to={'/MicrobiologyLab'}>Microbiology Lab</Link></li>
                                    <li><Link to={'/ComputerLab'}>Computer Lab</Link></li>	
                                    <li><Link to={'/HerbalGarden'}>Herbal Garden</Link></li>
                                    <li><Link to={'/MachineRoom'}>Machine Room</Link></li>
                                    <li><Link to={'/CentralInstrumentRoom'}>Central Instrument Room</Link></li>
                                    <li><Link to={'/Library'}>Library</Link></li>	
                                    <li><Link to={'/Auditorium'}>Auditorium</Link></li>
                                    <li><Link to={'/Cafeteria'}>Cafeteria</Link></li>
                                    <li><Link to={'/Transport'}>Transport</Link></li>
                                    <li><Link to={'/Hostel'}>Hostel</Link></li>	
                                    <li><Link to={'/WiFiCampus'}>Wi-Fi Campus</Link></li>	
                                    <li><Link to={'/Sports'}>Sports</Link></li>	
                                    <li><Link to={'/EmergencyMedicare'}>Emergency Medicare</Link></li>	
                                    <li><Link to={'/BankingFacility'}>Banking</Link></li>	
                                    <li><Link to={'/Security'}>Campus Security</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default AirConditionedClassrooms
