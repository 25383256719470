import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'

const PlacementRecord202324 = () => {
  return (
    <>
    <Bredcrumb title="Placement Record 2023-24" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8">
                       <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    <th>NAME OF STUDENTS</th>
                                    <th>DEPARTMENT</th>
                                    <th>COMPANY</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1.</td>
                                <td>Abhay Singh Chauhan</td>
                                <td>Sales Executive</td>
                                <td>Alembic Pharmaceutical Ltd.</td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>Avinash kr.Singh</td>
                                <td>Marketing Executive</td>
                                <td>Torrent Pharmaceutical Ltd.</td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>Uday Pratap Singh</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd.</td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>Mandeep Kumar</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd</td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td>Vidit Rastogi</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd</td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td>Sageer Ahmad</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd</td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td>Altab Ahmad</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd</td>
                            </tr>
                            <tr>
                                <td>8.</td>
                                <td>Bhagwanta Prasad</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd</td>
                            </tr>
                            <tr>
                                <td>9.</td>
                                <td>Hiritik Narayan</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd</td>
                            </tr>
                            <tr>
                                <td>10.</td>
                                <td>Abhisek Sharma</td>
                                <td>Marketing Executive</td>
                                <td>Nouveau Medicament Pvt. Ltd</td>
                            </tr>
                            <tr>
                                <td>11.</td>
                                <td>Amit Kumar</td>
                                <td>Marketing Executive</td>
                                <td>Cipla Pharmaceutical Pvt. Ltd.</td>
                            </tr>
                            <tr>
                                <td>12.</td>
                                <td>Aniket Sharma</td>
                                <td>Sales execuetive</td>
                                <td>Alteus  Biogenics</td>
                            </tr>
                            <tr>
                                <td>13.</td>
                                <td>Jai Kishan Gupta</td>
                                <td>QC Trainee</td>
                                <td>Macleods Pharmaceutical Ltd.</td>
                            </tr>
                            <tr>
                                <td>14.</td>
                                <td>Monika Pal</td>
                                <td>OC Officer</td>
                                <td>Akums Pharmaceutical Ltd.</td>
                            </tr>
                            <tr>
                                <td>15.</td>
                                <td>Rizwan Jafar</td>
                                <td>Marketing Executive</td>
                                <td>Cadila Pharmaceutical Pvt Ltd.</td>
                            </tr>
                            <tr>
                                <td>16.</td>
                                <td>Priyanshu Sharma</td>
                                <td>Marketing Executive</td>
                                <td>Merck Pharmaceutical Pvt Ltd.</td>
                            </tr>
                            <tr>
                                <td>17.</td>
                                <td>Akash Gupta</td>
                                <td>Marketing Executive</td>
                                <td>Merck Pharmaceutical Pvt Ltd.</td>
                            </tr>
                            <tr>
                                <td>18.</td>
                                <td>Avunash Kumar Singh</td>
                                <td>Marketing Executive</td>
                                <td>Triokaa Pharmaceutical Ltd.</td>
                            </tr>
                            <tr>
                                <td>19.</td>
                                <td>Arjun Kumar Jaiswal</td>
                                <td>Marketing Executive</td>
                                <td>Triokaa Pharmaceutical Ltd.</td>
                            </tr>
                            <tr>
                                <td>20.</td>
                                <td>Himanshu Singh</td>
                                <td>Marketing Executive</td>
                                <td>Triokaa Pharmaceutical Ltd.</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Placement</h4>
                                <ul class="sidebar-links">
                                    <li><Link to={'/PlacementRecord202324'}>Placement Record 2023-24</Link></li>
                                    <li><Link to={'/PlacementRecord2023'}>Placement Record 2022-23</Link></li>                                    
                                    <li><Link to={'/PlacementRecord201617'}>Placement Record 2016-17</Link></li>                                   
                                    <li><Link to={'/PlacmentGraph'}>Placment Graph</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                    </div>
              </div>
          </div>
      </section>
  </>
  )
}

export default PlacementRecord202324
