import React from 'react'
import { } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const PharmaceuticsLab = () => {
  return (
    <>
      <Bredcrumb title="Pharmaceutics Lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">                       
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                              <p>Pharmaceutics is the discipline of pharmacy that deals with all facets of the process or turning a new chemical entity (NCE) into a medication able to be safely and effectively used by patients in the community. Pharmaceutics deals with the formulation of pure drug substance into a dosage form. Branches of pharmaceutics include the study of relationship between drug formulation, delivery, disposition and clinical response.</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutics-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutics-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutics-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>                            
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default PharmaceuticsLab
