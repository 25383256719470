import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const Cafeteria = () => {
  return (
    <>
       <Bredcrumb title="Cafeteria" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>The institute has full-fledged cafeteria preparing and serving snacks, tea, coffee, cold drinks and various other foods.</p>                                                            
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/cafeteria-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/cafeteria-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Cafeteria
