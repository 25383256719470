import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const PlacementsData = () => {
  return (
    <>
    <Bredcrumb title="Placements Data" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8">
                        <h3 className='mb-3'>Placement Process</h3>
                        <ul className='list-style2'>
                            <li>Companies will come down to campus to conduct pre-placement talks. These talks will give students an idea about the recruiting organizations and opportunities available within these. It also gives students the chance to interact with employees of the company and learn more about the work culture. Based on these talks, students decide to apply for these organizations.</li>
                            <li>The willing students submit their resumes to the company after the presentation or to the Placement committee in case the companies wish to have them early prior to their arrival on campus.</li>
                            <li>Based on the resumes, their academic records etc. the companies prepare a list of eligible students and inform them advance to the Placement committee.</li>
                            <li>The companies inform the Placement Committee about the recruitment procedure followed by them, like Group Discussions, Case Studies, Personal Interviews, written test etc. After the selection process, the companies announce the list of selected candidates on the campus itself followed by appointment letters. Our Placement AND CRC Team glad to consider any other idea to help the recruiter’s to build stronger relations.</li>
                        </ul>
                        <h3 className='mt-4 mb-3'>Placement Policy</h3>
                        <ul className='list-style2'>
                            <li>Student expected to complete his/her degree by June is eligible for placement.</li>
                            <li>Placement support is available to all the students registered/verified/authenticated with the Placement Portal only.</li>
                            <li>If a student after the recruitment process is placed in a particular company (for eg. Company C1), he or she would not be allowed to apply to another company in the same category (for eg. Company C2). However, there will be an option for a ‘Dream Company’. This means that the student can select or apply in any company in the other two categories A & B.</li>
                            <li>If a student is placed in any company of category B (for eg. Company B1), he or she would not be allowed to apply to any other company of category B (for eg. Company B2)</li>
                            <li>If a student is placed in any company in category A, he/she will exhaust all further placement opportunities.</li>
                            <li>If a student’s name is enlisted in the Waiting List of a particular company, it will not be considered as a placement and he/she will be allowed to apply in other companies.</li>
                            <li>If there is a time gap between the recruitment process and the declaration of the results by a particular company (say Company B1), the student will be allowed to apply to another company (say Company B2) during this period. But in case he/she is selected in Company B1 as well as Company B2, he/she will not be allowed to apply further (except for Category A Companies.</li>
                        </ul>
                    </div>
                    <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Placement</h4>
                                <ul class="sidebar-links">
                                    <li><Link to={'/PlacementRecord202324'}>Placement Record 2023-24</Link></li>
                                    <li><Link to={'/PlacementRecord2023'}>Placement Record 2022-23</Link></li>                                    
                                    <li><Link to={'/PlacementRecord201617'}>Placement Record 2016-17</Link></li>                                   
                                    <li><Link to={'/PlacmentGraph'}>Placment Graph</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                    </div>
              </div>
          </div>
      </section>
  </>
  )
}

export default PlacementsData
