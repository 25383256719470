import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const Pharmacognosylab = () => {
  return (
    <>
      <Bredcrumb title="Pharmacognosy Lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>Plant materials are used both in developed and developing countries as home remedies, over the counter drug products. Since ancient times plants have been the source of natural products and in today’s scenario several of them are model drugs for synthetic products. This lab is meant for extraction, isolation, purification, and identification of active chemical constituents from medicinal plants. The lab is equipped with modern equipments and instruments like Soxhlet extractors, compound microscopes, rotary evaporator, IR moisture balance, laminar air flow bench etc. The lab also has a good collection of crude drugs and their herbariums. At the end of the lab course, students are able to interpret the morphological and anatomical descriptions of the crude drugs and sound in terms of screening of plants for drug discovery.</p>                                                        
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacognosy-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacognosy-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacognosy-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacognosy-04.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacognosy-05.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmacognosy-06.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Pharmacognosylab
