import React from 'react'
import { } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const PharmaceuticalAnalysis = () => {
  return (
    <>
      <Bredcrumb title="Pharmaceutical Analysis Lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p>Pharmaceutical Analysis is one of the most important specialized branches of Pharmaceutical Sciences concerned with the Quality Control of Pharmaceutical Products. Pharmaceutical analysis provides information on the identity, purity, content and stability of starting materials, excipients and active pharmaceutical ingredients (APIs). A distinction is made between analysis of the pure active ingredients used to cure, alleviate, prevent or identify illnesses and diseases (active ingredient analysis) and analysis of medicinal preparations.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_analysis_01.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>                                
                                {/* <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_analysis_03.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div> */}
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_analysis_04.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/pharmaceutical_analysis_07.png" className='img-thumb-new' alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default PharmaceuticalAnalysis
