import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import {  } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const Transport = () => {
  return (
    <>
      <Bredcrumb title="Transport" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                               <p>We have buses and other vehicles available for transportation. Our drivers are specially trained to deal with students softly so that any undue impact can be avoided on the student's minds. The transport facility is available from different points of Lucknow City.</p>                                                          
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/transport-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/transport-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/transport-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                               
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Transport
