import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'


const Admission = (props) => {
  return (
    <>
        <Bredcrumb title="Admission" />
        <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div className="col-md-4">
                        <div class="service-3 hover-service" data-aos="fade-up">
                            <img src="img/content/b-pharm01.png" alt="..." />
                            <div class="service-brief">
                                <div class="elementor-flip-box-layer-overlay">
                                    <p className='h-auto'>2 Years full-time post graduate course in Pharmacology and Pharmaceutics.</p>
                                    <p className="fw-bold">Intake : Pharmaceutics - 09 seats</p>						
                                    <p className="fw-bold">Intake : Pharmacology - 09 seats</p>
                                    <Link to={'https://pgcuet.samarth.ac.in/'} target='blank' class="elementor-button">click here</Link>
                                </div>
                            </div>
                            <div class="elementor-flip-box-layer-overlay-1">
                                <h4>M. PHARM. (Master of Pharmacy)</h4>                                            
                            </div>
                        </div>						
                    </div>	 

                    <div className="col-md-4">
                        <div class="service-3 hover-service" data-aos="fade-up">
                            <img src="img/content/b-pharm01.png" alt="..." />
                            <div class="service-brief">
                                <div class="elementor-flip-box-layer-overlay">
                                    <h4>B. PHARM. (Bachelor of Pharmacy)</h4>
                                    <p>4 Years full-time degree course in Pharmacy.</p>
                                    <p className="fw-bold">Intake: 100 Student</p>
                                    <Link to={'https://cuetug.ntaonline.in/frontend/web/site/login'} target='blank' class="elementor-button">click here</Link>
                                </div>
                            </div>
                            <div class="elementor-flip-box-layer-overlay-1">
                                <h4>B. PHARM. (Bachelor of Pharmacy)</h4>                                            
                            </div>
                        </div>						
                    </div>	 

                    <div className="col-md-4">
                        <div class="service-3 hover-service" data-aos="fade-up">
                            <img src="img/content/d-pharm01.png" alt="..." />
                            <div class="service-brief">
                                <div class="elementor-flip-box-layer-overlay">
                                    <h4>D. PHARM. (Diploma in Pharmacy)</h4>
                                    <p>2 Years Full-Time Diploma Course in Pharmacy.</p>
                                    <p className="fw-bold">Intake: 60 Student</p>
                                    <Link to={'https://admissions.nic.in/jeecup/Applicant/Root/Home.aspx?enc=Nm7QwHILXclJQSv2YVS+7qMYkJFqElfnj4WD9Sq/X3lU3FvfMVWyPbBFbdSRv44K'} target='blank' class="elementor-button">click here</Link>
                                </div>
                            </div>
                            <div class="elementor-flip-box-layer-overlay-1">
                                <h4>D. PHARM. (Diploma in Pharmacy)</h4>
                            </div>
                        </div>						
                    </div>	 
                </div>
            </div>
        </section>
    </>
  )
}

export default Admission
