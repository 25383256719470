import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const MachineRoom = () => {
  return (
    <>
    <Bredcrumb title="Machine Room" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8">
                        <div className='row'>
                                <div className='col-md-12'>
                                    <p>Mahatma Gandhi Institute of Pharmacy is having a well-established Machine room containing various machines required for various Unit Operations simulating with Pharmaceutical Industry Unit operations. Machine room is designed to acquire skills in learning the manufacture of various solid, liquid and semi solid formulations and Machines are also available for evaluation of several pharmaceutical dosage forms.The machine room equipped with most advanced instruments and machinery used in manufacturing of tablets, capsules, injectables, liquid orals and ointment sections. The Machine room is provided with stabilized electric power supply system. Each machine has separate platform with Standard Operating Procedure, Log Book, Standard Cleaning Procedure and Calibration or validation and operation manual. This laboratory provides a window for young and aspiring pharmacist to be well educated with the manufacturing aspect of different formulation in real time.</p>
                                </div>
                            </div>
                      <div class="row">
                        <div class="col-lg-12">                          
                          <div className="row">
                              <div className="col-lg-4 col-md-6 mb-3">
                                  <div className="gallary-block">
                                      <div className="position-relative">
                                          <img src="img/content/machine_01.png" className='img-thumb-new' alt="..." />                                           
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 mb-3">
                                  <div className="gallary-block">
                                      <div className="position-relative">
                                          <img src="img/content/machine_02.png" className='img-thumb-new' alt="..." />                                           
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 mb-3">
                                  <div className="gallary-block">
                                      <div className="position-relative">
                                          <img src="img/content/machine_03.png" className='img-thumb-new' alt="..." />                                           
                                      </div>
                                  </div>
                              </div>
                          
                              <div className="col-lg-4 col-md-6 mb-3">
                                  <div className="gallary-block">
                                      <div className="position-relative">
                                          <img src="img/content/machine_04.png" className='img-thumb-new' alt="..." />                                           
                                      </div>
                                  </div>
                              </div>
                              {/* <div className="col-lg-4 col-md-6 mb-3">
                                  <div className="gallary-block">
                                      <div className="position-relative">
                                          <img src="img/content/machine_05.png" className='img-thumb-new' alt="..." />                                           
                                      </div>
                                  </div>
                              </div> */}
                              <div className="col-lg-4 col-md-6 mb-3">
                                  <div className="gallary-block">
                                      <div className="position-relative">
                                          <img src="img/content/machine_06.png" className='img-thumb-new' alt="..." />                                           
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6 mb-3">
                                  <div className="gallary-block">
                                      <div className="position-relative">
                                          <img src="img/content/machine_07.png"  className='img-thumb-new' alt="..." />                                           
                                      </div>
                                  </div>
                              </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="sidebar">                          
                        <div class="common-section-item">
                          <div class="card">
                          <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
          </div>
      </section>
  </>
)
}

export default MachineRoom
