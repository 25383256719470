import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const Sports = () => {
  return (
    <>
      <Bredcrumb title="Sports" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                               <p>Sports are an important part of every society, country and individual. Participation in sports builds up team spirit, healthy competition and skill development which are essential elements in the development of well-balanced young people. A strong sense of sportsmanship and fair play is fostered among the whole student community. The qualities of the leadership, sharing, team spirit and tolerance are learnt from sports. Sports not only teach the students to maintain the physical stamina, but also the habit of obedience, discipline, the determination to win, willpower, etc. Every year College organizes a sports week for students. The winners are awarded with Certificates and Medals.</p>                                                      
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block gallary_block_new">
                                        <div className="position-relative">
                                            <img src="img/content/SportsFeild-09.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block gallary_block_new">
                                        <div className="position-relative">
                                            <img src="img/content/SportsFeild-13.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block gallary_block_new">
                                        <div className="position-relative">
                                            <img src="img/content/SportsFeild-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block gallary_block_new">
                                        <div className="position-relative">
                                            <img src="img/content/SportsFeild-14.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block gallary_block_new">
                                        <div className="position-relative">
                                            <img src="img/content/SportsFeild-15.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block gallary_block_new">
                                        <div className="position-relative">
                                            <img src="img/content/SportsFeild-06.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>                              
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Sports
