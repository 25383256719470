import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const TrainingPlacements = () => {
  return (
    <>
    <Bredcrumb title="Training Placements" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                    <div class="col-lg-12">
                        <h3 className='mb-3'>TIPS FOR STUDENT</h3>
                        <ul className='list-style2'>
                            <li>This is the first and foremost thing to do during campus placement drive. You should never miss Company’s pre-placement talks and presentations because most of the times questions in HR interview are asked directly from what was conveyed during company’s pre-placement talk during campus placement drive. It gives you a background of what company is doing, domain in which company is working and most important, technology that company is working on. This helps you in identifying if this is the right company for you. You should note down the points that are being conveyed during the presentation such as Annual Turn Over of the company, details about CEO and other important people in Company and their employee strength, locations and product details. Never show a disinterest to the points that are conveyed during introduction sessions in placement drive. In case there is no pre placement presentation it is highly recommended that one should do through research regarding the organization’s profile on internet.</li>
                            <li>Keep your Curriculum Vitae (CV) and other important documents in a professional document folder. You can carry two three copies of CV with yourself and never make a mistake of showing a Xerox copy of your CV during campus placement drive. Carry extra copies of all documents and have extra photographs as well. You must have read your CV thoroughly before sitting in placement drive.</li>
                            <li>Your dress will define your professionalism before the recruiters. So, never come in casual/party wear during campus placement drive. You must be in proper formals during campus placement drive. Apart from proper formals, you must also take care of your color matching. Get yourself a proper haircut and have a clean shave before sitting in a campus placement drive. You can also use a mild deodorant but in a proper quantity.</li>
                        </ul>
                        <h4 className='pt-4'>Details of Placement In-Charge</h4>
                        <p>Dr. Raziuddin Khan, Mr. Rishab Maurya</p>
                       
                        <p>Placement Cell, MGIP, Lucknow. <span className='fw-bold'>Contact No.:</span> 08090183694, 7007357050 <span className='fw-bold'>Email:</span> <Link to='mailti:mgip.placements@gmail.com'>mgip.placements@gmail.com</Link></p>
                    </div>
                  {/* <div class="col-lg-7">
                        <h3 className='mb-3'>Placement Process</h3>
                        <ul className='list-style2'>
                            <li>Companies will come down to campus to conduct pre-placement talks. These talks will give students an idea about the recruiting organizations and opportunities available within these. It also gives students the chance to interact with employees of the company and learn more about the work culture. Based on these talks, students decide to apply for these organizations.</li>
                            <li>The willing students submit their resumes to the company after the presentation or to the Placement committee in case the companies wish to have them early prior to their arrival on campus.</li>
                            <li>Based on the resumes, their academic records etc. the companies prepare a list of eligible students and inform them advance to the Placement committee.</li>
                            <li>The companies inform the Placement Committee about the recruitment procedure followed by them, like Group Discussions, Case Studies, Personal Interviews, written test etc. After the selection process, the companies announce the list of selected candidates on the campus itself followed by appointment letters. Our Placement AND CRC Team glad to consider any other idea to help the recruiter’s to build stronger relations.</li>
                        </ul>
                        <h3 className='mt-4 mb-3'>Placement Policy</h3>
                        <ul className='list-style2'>
                            <li>Student expected to complete his/her degree by June is eligible for placement.</li>
                            <li>Placement support is available to all the students registered/verified/authenticated with the Placement Portal only.</li>
                            <li>If a student after the recruitment process is placed in a particular company (for eg. Company C1), he or she would not be allowed to apply to another company in the same category (for eg. Company C2). However, there will be an option for a ‘Dream Company’. This means that the student can select or apply in any company in the other two categories A & B.</li>
                            <li>If a student is placed in any company of category B (for eg. Company B1), he or she would not be allowed to apply to any other company of category B (for eg. Company B2)</li>
                            <li>If a student is placed in any company in category A, he/she will exhaust all further placement opportunities.</li>
                            <li>If a student’s name is enlisted in the Waiting List of a particular company, it will not be considered as a placement and he/she will be allowed to apply in other companies.</li>
                            <li>If there is a time gap between the recruitment process and the declaration of the results by a particular company (say Company B1), the student will be allowed to apply to another company (say Company B2) during this period. But in case he/she is selected in Company B1 as well as Company B2, he/she will not be allowed to apply further (except for Category A Companies.</li>
                        </ul>
                  </div> */}
                  {/* <div class="col-lg-5">
                        <div class="xs-text-center mt-5">
                            <img src="img/content/computer_lab.png" alt="" class="border-radius-6" />
                        </div>
                  </div> */}
              </div>
          </div>
      </section>
  </>
  )
}

export default TrainingPlacements
