import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const OurRecruiter = () => {
  return (
    <>
      <Bredcrumb title="Our Recruiters" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row">
                          <div class="col-lg-12">                            
                            <div className="row">
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/abbot.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/akums.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/alembic.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/alkem.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/allergan.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">
                                      <div className="logo_clint">
                                            <img src="img/client/aroygya.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/aurobindo.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/cadila.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/cipla.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/drreddy.jpg" alt="..." />                                           
                                        </div>                                  
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/emcure.jpg" alt="..." />                                           
                                        </div>                                  
                                </div>                                
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/glenmark.jpg" alt="..." />                                           
                                        </div>                                  
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/gsk.jpg" alt="..." />                                           
                                        </div>                                    
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/ipca.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/janssen.jpg" alt="..." />                                           
                                        </div>                                  
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/jubilant.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/lupin.jpg" alt="..." />                                           
                                        </div>
                                 </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/mankind.jpg" alt="..." />                                           
                                        </div>                                 
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/merk.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/mora.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/mylan.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/natco.jpg" alt="..." />                                           
                                        </div>                                    
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/org.jpg" alt="..." />                                           
                                        </div>                                  
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/panacea.jpg" alt="..." />                                           
                                        </div>
                                 </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/pfizer.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/piramal.jpg" alt="..." />                                           
                                        </div>                                    
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/sanofi.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/sun.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/themis.jpg" alt="..." />                                           
                                        </div>                                    
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/tkk.jpg" alt="..." />                                           
                                        </div>                                    
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/torrent.gif" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/windlas.jpg" alt="..." />                                           
                                        </div>                                    
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/windlasbiotech.jpg" alt="..." />                                           
                                        </div>                                   
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/zee.jpg" alt="..." />                                           
                                        </div>                                    
                                </div> 
                                <div className="col-lg-2 col-md-4 mb-3">                                    
                                        <div className="logo_clint">
                                            <img src="img/client/zydus.jpg" alt="..." />                                           
                                        </div>
                                    
                                </div> 
                            </div>
                          </div>
                        </div>
                      </div>                      
                    </div>
            </div>
        </section>
    </>
  )
}

export default OurRecruiter
