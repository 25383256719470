import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Common/Bredcrumb'

const PlacementDetail2022 = () => {
  return (
    <>
    <Bredcrumb title="Placement Detail 2022" />
    <section className='mt-4'>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8">                                             
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    <th>Organization / Company</th>
                                    <th>Department</th>
                                    <th>Package</th>
                                    <th>Students</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1.</td>
                                <td>ALEMBIC PHARMACEUTICAL LTD.</td>
                                <td>MEDICAL REPRESENTATIVE</td>
                                <td>3.6 Lakh per annum</td>
                                <td>ABHAY SINGH CHAUHAN</td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>TORRENT PHARMACEUTICAL LTD.</td>
                                <td>MEDICAL REPRESENTATIVE</td>
                                <td>3.2 Lakh per annum</td>
                                <td>AVINASH KR.SINGH</td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>NOUVEAU MEDICAMENT PVT. LTD.</td>
                                <td>MEDICAL REPRESENTATIVE</td>
                                <td>3.0 Lakh per annum</td>
                                <td>MANDEEP KUMAR</td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>NOUVEAU MEDICAMENT PVT. LTD.</td>
                                <td>MEDICAL REPRESENTATIVE</td>
                                <td>3.0 Lakh per annum</td>
                                <td>HRITIK NARAYAN</td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td>CIPLA PHARMACEUTICAL PRIVATE LIMITED</td>
                                <td>Junior Team Member- QC</td>
                                <td>2.4 Lakh per annum</td>
                                <td>HIMANSHU SINGH</td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td>CIPLA PHARMACEUTICAL PRIVATE LIMITED</td>
                                <td>Junior Team Member- QC</td>
                                <td>2.4 Lakh per annum</td>
                                <td>GAURAV PANDEY</td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td>NOUVEAU MEDICAMENT PVT. LTD</td>
                                <td>MEDICAL REPRESENTATIVE</td>
                                <td>3.0 Lakh per annum</td>
                                <td>SAGEER AHMAD</td>
                            </tr>
                            <tr>
                                <td>8.</td>
                                <td>NOUVEAU MEDICAMENT PVT. LTD</td>
                                <td>MEDICAL REPRESENTATIVE</td>
                                <td>3.0 Lakh per annum</td>
                                <td>VIDIT RASTOGI</td>
                            </tr>                            
                        </tbody>
                        </table>
                    </div>
                    <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="title-bg title-bg-default">Placement</h4>
                                <ul class="sidebar-links">
                                    <li><Link to={'/PlacementRecord2023'}>Placement Record 2022-23</Link></li>
                                    <li><Link to={'/PlacementRecord2022'}>Placement Record 2023-24</Link></li>
                                    <li><Link to={'/PlacementRecord201617'}>Placement Record 2016-17</Link></li>                                   
                                    <li><Link to={'/PlacmentGraph'}>Placment Graph</Link></li>
                                </ul>
                              </div>
                            </div>
                          </div>                        
                        </div>
                    </div>
              </div>
          </div>
      </section>
  </>
  )
}

export default PlacementDetail2022
