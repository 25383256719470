import React from 'react'
import { Link } from 'react-router-dom'

const Bredcrumb = (props) => {

    // let arr=[ 
    //     {
    //         img:'/img/slider/01.png',       
    //     },
    //         {
    //         img:'/img/slider/01.png',       
    //     },
    //         {
    //         img:'/img/slider/01.png',        
    //     },    

    //     {
    //         img:'/img/slider/01.png',       
    //     },   
        
    //     {
    //         img:'/img/slider/01.png',        
    //     },  
    // ]
    
  return (

    
    <section class="page-title-section bg-img cover-background" data-overlay-dark="7"  style={{backgroundImage:'url("img/slider/02.png")'}}>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1>{props.title}</h1>
                </div>
                <div class="col-md-12">
                    <ul>
                        <li><Link to={'/'}>Home</Link></li>
                        <li><Link  to={'/'}>{props.title}</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    
  )
}

export default Bredcrumb
