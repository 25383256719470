import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const HerbalGarden = () => {
  return (
    <>
      <Bredcrumb title="Herbal Garden" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>A herbal garden is a curated space dedicated to cultivating a diverse range of herbs for various purposes. These gardens can be designed for medicinal, culinary, aromatic, or decorative uses, offering a rich tapestry of benefits to gardeners and enthusiasts alike. Medicinal herbs like chamomile, echinacea, and lavender are often grown for their healing properties, deeply rooted in traditional medicine systems. Culinary herbs such as basil, thyme, and oregano add flavor and zest to dishes, making them essential in kitchen gardens. Aromatic herbs like lavender, rosemary, and sage not only smell delightful but also find use in perfumery and aromatherapy. Decorative herbs, with their ornamental foliage and flowers, enhance the visual appeal of gardens and landscapes. Herbal tea gardens provide a haven for herbs like peppermint, chamomile, and lemon balm, perfect for brewing soothing and aromatic teas. Beyond their functional uses, herbal gardens offer a sanctuary for relaxation, biodiversity support by attracting beneficial insects, and a hands-on experience in natural remedies. Regular maintenance, including watering, pruning, and weeding, ensures the vitality and productivity of herbal gardens, rewarding enthusiasts with a bounty of fresh, fragrant herbs for diverse purposes.</p>                                                            
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/herbal-garden-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/herbal-garden-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/herbal-garden-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/herbal-garden-04.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/herbal-garden-05.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/herbal-garden-06.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default HerbalGarden
