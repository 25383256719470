import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const Events = () => {
  return (
    <>
      <Bredcrumb title="Events" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row">                            
                            <div className="col-md-4">	
                                <div class="service-2 hover-service" data-aos="fade-up">
                                    <img src="img/content/events-01.png" alt="..." />
                                    <div class="service-brief">
                                        <div class="elementor-flip-box-layer-overlay mt-5 pt-4">
                                            <h4>Fresher Party 2023</h4>
                                            <div class="btn-inline-button">
                                                <Link to={'/FresherParty'} class="elementor-button">Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-flip-box-layer-overlay-1">
                                        <h4>Fresher Party 2023</h4>
                                    </div>
                                </div>	
                            </div>

                            <div className="col-md-4">	
                                <div class="service-2 hover-service" data-aos="fade-up">
                                    <img src="img/content/cimap-04.png" alt="..." />
                                    <div class="service-brief">
                                        <div class="elementor-flip-box-layer-overlay mt-5 pt-4">
                                            <h4>Field visit at CIMAP</h4>
                                            <div class="btn-inline-button">
                                                <Link to={'/FieldVisitCimap'} class="elementor-button">Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-flip-box-layer-overlay-1">
                                        <h4>Field visit at CIMAP</h4>
                                    </div>
                                </div>	
                            </div>

                            <div className="col-md-4">	
                                <div class="service-2 hover-service" data-aos="fade-up">
                                    <img src="img/content/fieldvisitnbri-01.png" alt="..." />
                                    <div class="service-brief">
                                        <div class="elementor-flip-box-layer-overlay mt-5 pt-4">
                                            <h4>Field visit NBRI</h4>
                                            <div class="btn-inline-button">
                                                <Link to={'/FieldVisitNbri'} class="elementor-button">Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-flip-box-layer-overlay-1">
                                        <h4>Field visit NBRI</h4>
                                    </div>
                                </div>	
                            </div>

                            <div className="col-md-4">	
                                <div class="service-2 hover-service" data-aos="fade-up">
                                    <img src="img/content/visithospital-24.png" alt="..." />
                                    <div class="service-brief">
                                        <div class="elementor-flip-box-layer-overlay mt-5 pt-4">
                                            <h4>Visit at St Mary Hospital</h4>
                                            <div class="btn-inline-button">
                                                <Link to={'/VisitHospital'} class="elementor-button">Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-flip-box-layer-overlay-1">
                                        <h4>Visit at St Mary Hospital</h4>
                                    </div>
                                </div>	
                            </div>

                            <div className="col-md-4">	
                                <div class="service-2 hover-service" data-aos="fade-up">
                                    <img src="img/content/plantation-03.png" alt="..." />
                                    <div class="service-brief">
                                        <div class="elementor-flip-box-layer-overlay mt-5 pt-4">
                                            <h4>Plantation</h4>
                                            <div class="btn-inline-button">
                                                <Link to={'/Plantation'} class="elementor-button">Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-flip-box-layer-overlay-1">
                                        <h4>Plantation</h4>
                                    </div>
                                </div>	
                            </div>

                            <div className="col-md-4">	
                                <div class="service-2 hover-service" data-aos="fade-up">
                                    <img src="img/content/SportsFeild-01.png" alt="..." />
                                    <div class="service-brief">
                                        <div class="elementor-flip-box-layer-overlay mt-5 pt-4">
                                            <h4>Sports Meet</h4>
                                            <div class="btn-inline-button">
                                                <Link to={'/SportsMeet'} class="elementor-button">Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-flip-box-layer-overlay-1">
                                        <h4>Sports Meet</h4>
                                    </div>
                                </div>	
                            </div>

                        </div>

                        {/* <div class="row">
                            
                            <div className="col-md-4">	
                                <Link to={'/FresherParty'} className='text-dark'>
                                    <div className="text-center sec_top align-middle">
                                        <p className="small-head">Fresher Party 2023</p>						
                                    </div>
                                </Link>
                            </div>       
                            <div className="col-md-4">	
                                <Link to={'/FieldVisitCimap'} className='text-dark'>
                                    <div className="text-center sec_top align-middle">
                                        <p className="small-head">Field visit at CIMAP</p>						
                                    </div>
                                </Link>
                            </div>  
                            <div className="col-md-4">	
                                <Link to={'/FieldVisitNbri'} className='text-dark'>
                                    <div className="text-center sec_top align-middle">
                                        <p className="small-head">Field visit NBRI</p>						
                                    </div>
                                </Link>
                            </div> 
                            <div className="col-md-4">	
                                <Link to={'/VisitHospital'} className='text-dark'>
                                    <div className="text-center sec_top align-middle">
                                        <p className="small-head">Visit at St Mary Hospital</p>						
                                    </div>
                                </Link>
                            </div>  
                             
                            <div className="col-md-4">	
                                <Link to={'/Plantation'} className='text-dark'>
                                    <div className="text-center sec_top align-middle">
                                        <p className="small-head">Plantation</p>						
                                    </div>
                                </Link>
                            </div>    

                            <div className="col-md-4">	
                                <Link to={'/SportsFeild'} className='text-dark'>
                                    <div className="text-center sec_top align-middle">
                                        <p className="small-head">Sports</p>						
                                    </div>
                                </Link>
                            </div>     
                        </div> */}
                      </div>                    
                    </div>
            </div>
        </section>

        
    </>
  )
}

export default Events
