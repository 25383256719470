import React, { } from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const About = (props) => {
      
  return (
    <>
      <Bredcrumb title="About" />

      <section class="about-section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-sm-12">
                        <div class="padding-20px-right md-padding-20px-right sm-no-padding-right">
                            <h3 class="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 margin-30px-bottom md-margin-25px-bottom sm-margin-20px-bottom text-theme-color">Welcome to Mahatma Gandhi Institute of Pharmacy</h3>
                            <p class="margin-30px-bottom md-margin-25px-bottom sm-margin-20px-bottom font-size16 line-height-30">We are highly captivated to welcome you all in Mahatma Gandhi Institute of Pharmacy (MGIP), Where education does not commence only with the 'Books'. Our aim is to impart all round quality education to the Students with a view to shape them into well rounded, welladjusted and well-tuned Professionals, capable of making their future in various walks of life. We are designed to achieve efficient prefectural system in pharmacy for students.</p>
                            <p>MGIP came into existence since 2008, is exclusively consecrated in the field of Pharmaceutical education & research and continuously generating pharmacy graduates and post graduates. It's also offering opportunities to contribute to the pharmaceutical industry right from licensed pharmacists to pharmaceutical scientists who develop the medications, actively involved in dosage form design, conduct research, recrudesce formulation and are administrators in the pharmaceutical industry.</p>
                            <p>Gallant and aspirational, down-to-earth and friendly, we offer you a world-class learning experience that's bedded in a rich educational heritage at MGIP. Cutting-edge research and innovation feeds into teaching resources and methodologies, while you'll find countless opportunities for extra-curricular activities and skills development.</p>
                            <p>By choosing to study at the Mahatma Gandhi Institute of Pharmacy you are investing in an education that will help you build a successful future. We will support your learning by providing an educational experience designed to help you unlock and explore your full potential.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">                        
                        <div className="news-bg-column news-bg-01">
                            <p className="news-head">Facebook</p>
                            <iframe className="fac_ifrma" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmgip391%2F%3Fref%3Dpy_c&tabs=timeline&width=340&height=400px&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" ></iframe>
                        </div>                            
                    </div>
                </div>
            </div>
        </section>
       
        {/* <section class="bg-light-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12 center-col margin-50px-bottom sm-margin-40px-bottom text-center">
                        <h3 class="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 section-title">Testimonials</h3>
                        <div class="title-border"><span class="lg"></span><span class="md"></span><span class="sm"></span></div>
                    </div>
                </div>
                <div class="testimonial-style1 owl-theme owl-carousel" data-slider-id="1">
                    <div class="text-center">
                        <div class="testmonial-text">
                            <p class="font-size18 sm-font-size16 line-height-35 xs-line-height-28 margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque inventore veritatis et quasi architecto beatae.</p>
                            <h5 class="margin-5px-bottom font-size18 xs-font-size16"><a href="#!" class="text-theme-color">Stepha Kruse</a></h5>
                            <h6 class="font-size14 xs-font-size13 letter-spacing-1 font-weight-500 opacity6 no-margin-bottom">Designer</h6>
                        </div>
                    </div>
                    <div class="text-center">
                        <div class="testmonial-text">
                            <p class="font-size18 sm-font-size16 line-height-35 xs-line-height-28 margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">Aerspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque inventore veritatis et quasi architecto beatae.</p>
                            <h5 class="margin-5px-bottom font-size18 xs-font-size16"><a href="#!" class="text-theme-color">Dunican keithly</a></h5>
                            <h6 class="font-size14 xs-font-size13 letter-spacing-1 font-weight-500 opacity6 no-margin-bottom">Networking</h6>
                        </div>
                    </div>
                    <div class="text-center">
                        <div class="testmonial-text">
                            <p class="font-size18 sm-font-size16 line-height-35 xs-line-height-28 margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">Rerspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque inventore veritatis et quasi architecto beatae.</p>
                            <h5 class="margin-5px-bottom font-size18 xs-font-size16"><a href="#!" class="text-theme-color">Alivin Corondo</a></h5>
                            <h6 class="font-size14 xs-font-size13 letter-spacing-1 font-weight-500 opacity6 no-margin-bottom">Developer</h6>
                        </div>
                    </div>
                </div>
                <div class="owl-thumbs text-center" data-slider-id="1">
                    <button class="owl-thumb-item border-radius-50 width-60px sm-width-50px xs-width-40px margin-5px-right"><img src="img/testmonials/t-7.jpg" class="border-radius-50" alt="" /></button>
                    <button class="owl-thumb-item width-60px border-radius-50 sm-width-50px xs-width-40px margin-5px-right"><img src="img/testmonials/t-8.jpg" class="border-radius-50" alt="" /></button>
                    <button class="owl-thumb-item width-60px sm-width-50px xs-width-40px border-radius-50"><img src="img/testmonials/t-9.jpg" class="border-radius-50" alt="" /></button>
                </div>
            </div>
        </section> */}
    </>
  )
}

export default About
