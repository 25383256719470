import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const MicrobiologyLab = () => {
  return (
    <>
      <Bredcrumb title="Microbiology Lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>A microbiology lab is a specialized facility designed for the study and analysis of microorganisms, including bacteria, viruses, fungi, and protozoa. These labs are crucial in various scientific disciplines such as medicine, biotechnology, environmental science, and the food industry. Equipped with advanced instruments and equipment, microbiology labs typically feature high-powered microscopes for observing microscopic organisms, culturing equipment like incubators and agar plates for growing microorganisms, and sterilization equipment such as autoclaves to maintain a sterile environment.</p>                                                            
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/microbiology-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/microbiology-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/microbiology-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default MicrobiologyLab
