import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'
const Auditorium = () => {
  return (
    <>
       <Bredcrumb title="Auditorium" />
        <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                    <div className='row'>
                                <div className='col-md-12'>
                                    <p>The college has a spacious auditorium with a seating capacity of more than 250 seats. The auditorium is furnished with a latest state of art acoustics system and a warm, welcoming ambience. It often plays host for conducting National Seminars, Guest Lectures by eminent personalities, National Pharmacy Week Celebrations, Seminar &amp; Poster presentations, Orientation Programmes, Cultural fest and all other college events. It is also a platform which offers the students to bring out their creative talent and augment their communication skills.</p>
                                </div>
                            </div>
                        <div class="row">
                          <div class="col-lg-12">                            
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/auditorium-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/auditorium-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/auditorium-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/auditorium-04.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/auditorium-05.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/auditorium-06.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Auditorium
