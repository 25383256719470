import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const Contact = () => {
  return (
    <>
     <Bredcrumb title="Contact Us" />
      
      
     <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mb-1-9 mb-lg-0">
                        <div class="pe-lg-1-9">
								<div class="row">
                                    <div className='col-md-12'>
                                        <div class="section-heading left half">
                                            <h3 class="font-weight-600">Get advice any time</h3>
                                        </div>
                                        <div class="d-flex border-bottom pb-4 mb-4 w-90">
                                            <div class="flex-shrink-0">
                                                <i class="fa fa-phone display-20 text-primary"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h4 class="mb-1 text-uppercase display-30">PHONE NUMBERS</h4>
                                                <p class="mb-0">+91 993 584 4704, +91 941 592 9663, +91 930 586 4359</p>
                                            </div>
                                        </div>
                                        <div class="d-flex border-bottom pb-4 mb-4 w-90">
                                            <div class="flex-shrink-0">
                                                <i class="fas fa-map-marker-alt display-20 text-primary"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h4 class="mb-1 text-uppercase display-30">LOCATION</h4>
                                                <p class="mb-0">Junabganj, Kanpur Road, Lucknow 227101 Uttar Pradesh</p>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-1-9 w-90">
                                            <div class="flex-shrink-0">
                                                <i class="far fa-envelope display-20 text-primary"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h4 class="mb-1 text-uppercase display-30">Email</h4>
                                                <p class="mb-0"><a href="mailto:director.mgip@gmail.com">director.mgip@gmail.com</a></p>
                                            </div>
                                        </div>
                                        {/* <ul class="social-icon-style7 ps-0 mt-4 w-90">
                                            <li><a href="#!"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#!"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#!"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="#!"><i class="fab fa-youtube"></i></a></li>
                                            <li><a href="#!"><i class="fab fa-linkedin-in"></i></a></li>
                                        </ul> */}
                                    </div>
								</div>
                        </div>

                    </div>

                    <div class="col-lg-6">
                        <div class="contact-form-box">
                            <div class="section-heading left half">
                                <h3 class="font-weight-600">Let's talk</h3>
                            </div>

                            <form class="quform" action="" method="post">
                                <div class="quform-elements">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="quform-element form-group mb-3">
                                                <div class="quform-input">
                                                    <input id="name" class="form-control" type="text" name="name" placeholder="Your name here" />
                                                </div>
                                            </div>
                                        </div>                                     
                                        <div class="col-md-6">
                                            <div class="quform-element form-group mb-3">
                                                <div class="quform-input">
                                                    <input id="email" class="form-control" type="text" name="email" placeholder="Your email here" />
                                                </div>
                                            </div>
                                        </div>    
                                        <div class="col-md-6">
                                            <div class="quform-element form-group mb-3">
                                                <div class="quform-input">
                                                    <input id="phone" class="form-control" type="text" name="phone" placeholder="Your phone number" />
                                                </div>
                                            </div>
                                        </div>                                    
                                        <div className="col-md-12  mb-3">                                           
                                            <div class="input-group quform-element form-group">                                                
                                                <select className='form-selct'>
                                                    <option>--Select Course --</option>
                                                    <option>M. Pharm.</option>
                                                    <option>B. Pharm.</option>
                                                    <option>D. Pharm.</option>                                                    
                                                </select>
                                            </div>
                                        </div>                                   
                                                                            
                                        <div class="col-md-12">
                                            <div class="quform-element form-group mb-3">
                                                <div class="quform-input">
                                                    <textarea id="message" class="form-control" name="message" rows="3" placeholder="Tell us a few words"></textarea>
                                                </div>
                                            </div>
                                        </div>                                       
                                        <div className="col-md-12">
                                            <div className="quform-submit-inner">
                                                <button className="butn fill">Submit <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i></button>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </form>                        

                        </div>
                    </div>

                </div>
            </div>
        </section>
       
        <iframe class="map" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1782.7962930124074!2d80.809915!3d26.661524!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c0e3708eb2d0b%3A0xf8b4c470592075f5!2sMahatma%20Gandhi%20Institute%20Of%20Pharmacy!5e0!3m2!1sen!2sus!4v1709663535996!5m2!1sen!2sus" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </>
  )
}

export default Contact
