import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'

const VisionMission = () => {
  return (
    <>
      <Bredcrumb title="Vision Mission" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                          <h3>Vision</h3>
                          <p>The institute envisions the hope that its graduates and postgraduates will be the leader of tomorrow. Their education is patterned with this view that creates an ambience in which new ideas, research and scholarship flourishes.</p>
                          <h3>Mission</h3>
                          <p>The institute aims to become a centre of excellence in pharmaceutical education & research. We are ready to provide quality pharmacy education supporting better health care program of the nation by training & educating students to contribute to the health of the community and who will become leaders and innovators of tomorrow having concern for the community and fellow beings.</p>
                          <h3>Objectives and Goals</h3>
                          <ul className='list-style2'>
                              <li>Provide quality pharmacy education supporting better healthcare program of the nation.</li>
                              <li>To train and educate students to contribute to the health of the community.</li>
                              <li>To train and educate students who will become leaders and innovators of tomorrow having concern for the community and fellow beings.</li>
                              <li>To train and educate students who could later contribute to the creation, application and adaptation of pharmaceutical education.</li>
                              <li>Play an important role in local & national initiative to nurture interest in health sciences to become specialist on medicines.</li>
                              <li>To advance pharmaceutical knowledge through research.</li>
                              <li>To develop world-class pharmacy manpower.</li>
                              <li>To enrich the professional knowledge of students through problem solving attitude.</li>
                          </ul>
                    </div>
                    <div class="col-lg-5">
                          <div class="xs-text-center mt-5">
                              <img src="img/content/mission-01.png" alt="" class="border-radius-6" />
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default VisionMission
