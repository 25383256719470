import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { Link } from 'react-router-dom'

const Sitemap = () => {
  return (
    <>
      <Bredcrumb title="Sitemap" />
      <div class="container">            
            <section id="sec1">                
                <div>
                    <div class="row">                       
                        <div className='col-md-3'>
                            <h6 className='pb-2'><Link to={'/About'}>About Us</Link></h6>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'/About'}>About MGIP</Link></li>
                                <li><Link to={'/VisionMission'}>Vision & Mission</Link></li>
                                <li><Link to={'/ChairpersonMessage'}>Chairperson Message</Link></li>
                                <li><Link to={'/DirectorMessage'}>Director Message</Link></li>
                                <li><Link to={'/PrincipalMessage'}>Principal Message</Link></li>																						
                            </ul>
                        </div>
                       
                        <div className='col-md-3'>
                            <h6 className='pb-2'><Link to={'/Courses'}>Admissions</Link></h6>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'/Courses'}>Courses</Link></li>
                                <li><Link to={'/FeeStructure'}>Fee Structure</Link></li>
                                <li><Link to={'/img/pdf/Admission_Form.pdf'} target='blank'>Admission Form</Link></li>
                                <li><Link to={'/img/pdf/brochure.pdf'} target='blank'>Brochure</Link></li>																						
                            </ul>
                        </div>
                        <div className='col-md-3'>
                            <h6 className='pb-2'><Link to={'/AntiDiscriminationCommittee'}>Committees</Link></h6>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'/AntiDiscriminationCommittee'}>Anti Discrimination Committee</Link></li>
                                <li><Link to={'/AntiRaggingCommittee'}>Anti Ragging Committee</Link></li>
                                <li><Link to={'/SCSTCommittee'}>SC/ST Standing Committee</Link></li>
                                <li><Link to={'/GenderSensitizationCommittee'}>Gender Sensitization Committee</Link></li>
                                <li><Link to={'/StudentGrievanceRedressalCommittee'}>Student Grievance Redressal Committee</Link></li>	
                            </ul>
                        </div>
                        
                        <div className='col-lg-3'>
                            <h6 className='pb-2'><Link to={'/Events'}>Media</Link></h6>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'/Events'}>Events</Link></li>
                                <li><Link to={'/Photo'}>Photo</Link></li>
                                <li><Link to={'/Video'}>Video</Link></li>																						
                            </ul>
                            <div className='col-lg-12'><h6 className='pb-2'><Link to={'/Contact'}>Contact</Link></h6></div>	
                        </div>
                    </div>

                    <div className='row mt-4'>
                        <h6 className='pb-2'><Link to={'/PharmaceuticsLab'}>Facilities</Link></h6>
                        <div className='col-md-3'>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'/PharmaceuticsLab'}>Pharmaceutics Lab</Link></li>
                                <li><Link to={'/Pharmacologylab'}>Pharmacology Lab</Link></li>
                                <li><Link to={'/Pharmacognosylab'}>Pharmacognosy Lab</Link></li>
                                <li><Link to={'/PharmaceuticalAnalysis'}>Pharmaceutical Analysis Lab</Link></li>		
                                <li><Link to={'/PharmaceuticalChemistry'}>Pharmaceutical Chemistry Lab</Link></li>                                                         
                            </ul>
                        </div>
                        <div className='col-md-3'>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'/HumanAnatomyPathology'}>Human Anatomy and Pathology Lab</Link></li>
                                <li><Link to={'/MicrobiologyLab'}>Microbiology Lab</Link></li>
                                <li><Link to={'/ComputerLab'}>Computer Lab</Link></li>	
                                <li><Link to={'/HerbalGarden'}>Herbal Garden</Link></li>
                                <li><Link to={'/MachineRoom'}>Machine Room</Link></li>                                
                            </ul>
                        </div>
                        <div className='col-md-3'>
                            <ul className='list-style2 pb-3'>                                
                                <li><Link to={'/CentralInstrumentRoom'}>Central Instrument Room</Link></li>
                                <li><Link to={'/Library'}>Library</Link></li>	
                                <li><Link to={'/Auditorium'}>Auditorium</Link></li>
                                <li><Link to={'/Cafeteria'}>Cafeteria</Link></li>
                                <li><Link to={'/Transport'}>Transport</Link></li>                               
                            </ul>
                        </div>
                        <div className='col-md-3'>
                            <ul className='list-style2 pb-3'>  
                                <li><Link to={'/Hostel'}>Hostel</Link></li>	
                                <li><Link to={'/WiFiCampus'}>Wi-Fi Campus</Link></li>	
                                <li><Link to={'/Sports'}>Sports</Link></li>	
                                <li><Link to={'/EmergencyMedicare'}>Emergency Medicare</Link></li>	
                                <li><Link to={'/BankingFacility'}>Banking Facility</Link></li>	
                            </ul>
                        </div>	
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-3'>
                            <h6 className='pb-2'><Link>Approval</Link></h6>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'/img/pdf/PCI_Approval.pdf'} target='blank'>PCI</Link></li>
                                <li><Link to={'/img/pdf/BTE_Approval.pdf'} target='blank'>BTE</Link></li>
                                <li><Link to={'/img/pdf/AKTU_Affiliation.pdf'} target='blank'>AKTU</Link></li>																						
                            </ul>
                        </div>
                        <div className='col-lg-3'>
                            <h6 className='pb-2'><Link>Achievements</Link></h6>
                            <ul className='list-style2 pb-3'>
                                <li><Link to={'https://erp.aktu.ac.in/WebPages/OneView/OneView.aspx'} target='blank'>Results</Link></li>
                                <li><Link to={'/AwardsAchievements'}>Awards & Achievements</Link></li>																						
                            </ul>
                        </div>
                        <div className='col-md-6'>
                            <h6 className='pb-2'><Link to={'/PlacementsData'}>Placements</Link></h6>
                            <div className='row'>
                                <div className='col-md-6'>
                                   
                                    <ul className='list-style2 pb-3'>
                                        <li><Link to={'/PlacementsData'}>Placements Data</Link></li>
                                        <li><Link to={'/TrainingPlacements'}>Training and Placements Cell</Link></li>
                                        <li><Link to={'/OurRecruiter'}>Our Recruiters</Link></li>
                                        <li><Link to={'/IndustrialVisit'}>Industrial visit</Link></li>																						
                                    </ul>
                                </div>
                                <div className='col-md-6'>
                                    <ul className='list-style2 pb-3'>
                                        <li><Link to={'/PlacementRecord202324'}>Placement Record 2023-24</Link></li>
                                        <li><Link to={'/PlacementRecord2023'}>Placement Record 2022-23</Link></li>
                                        <li><Link to={'/PlacementRecord201617'}>Placement Record 2016-17</Link></li>
                                        <li><Link to={'/PlacmentGraph'}>Placment Graph</Link></li>																						
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='row mt-4'>                        
                        <div className='col-lg-3'><h6 className='pb-2'><Link to={'/Faculty'}>Faculty</Link></h6></div>
                        <div className='col-lg-3'><h6 className='pb-2'><Link to={'/Alumni'}>Alumni</Link></h6></div>	
                        <div className='col-lg-3'><h6 className='pb-2'><Link to={'/Careers'}>Careers</Link></h6></div>	
                        <div className='col-lg-3'><h6 className='pb-2'><Link to={'/LatestUpdates'}>Latest Updates</Link></h6></div>
                    </div>	
                </div>
            </section>
            

</div>

    </>
  )
}

export default Sitemap
