import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const ComputerLab = () => {
  return (
    <>
      <Bredcrumb title="Computer Lab" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>Computer Lab has latest configuration desktop PCs on network controlled by server. Also the operating systems like Linux, Windows etc., various application software and database management system are available. The computer centre has the central power back up and will remain available to students at least 12-14 hours every day.</p>
                                <p>This facility will cater to the need of all students & help them learn developing innovative program/software when they are young. The computer centre also has the highspeed internet connection for the students. Campus has 3 Mbps internet facility. All laboratories, faculty room, other section & hostels have Wi-Fi enabled internet facilities.</p>                                                            
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/computer_lab-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/computer_lab-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/computer_lab-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default ComputerLab
