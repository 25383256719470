import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const Security = () => {
  return (
    <>
      <Bredcrumb title="Campus Security" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>The institution provides safe and secure campus for its students and staff. The campus is protected from all sides by construction of concrete compound wall. CCTV cameras are installed for 24 hours surveillance. Besides this, the security guards are posted for 24 hours at the main entrance of the campus and at the entry points of institutes.</p>
                                <p>The institute is also well equipped with safety requirements like Hydrant, sand bucket and sufficient mounting of fire extinguisher at each lab, corridors, central store, animal house and wherever required.</p>
                                <p>The college building is covered under general insurance for fire threat and earthquake.</p>                                                             
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/security-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/security-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/security-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/security-04.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/security-05.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/security-06.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/security-07.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Security
