import React from 'react'
import Bredcrumb from '../Common/Bredcrumb'
import { } from 'react-router-dom'
import FacilitiesSidebar from '../Common/FacilitiesSidebar'

const EmergencyMedicare = () => {
  return (
    <>
      <Bredcrumb title="Emergency Medicare" />
      <section className='mt-4'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="common-section-item">
                                <p>For meeting any emergency, we have round the clock facilities of doctor so that health of students can be looked after in the best possible manner. Health and welfare of students come first and it is our prime concern that your ward is well cures in the most scientific and holistic way. We have timely health check so that any untoward illness can be checked.</p>                                                            
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/emergency-01.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/emergency-02.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/emergency-03.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <div className="gallary-block">
                                        <div className="position-relative">
                                            <img src="img/content/emergency-04.png" alt="..." />                                           
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="sidebar">                          
                          <div class="common-section-item">
                            <div class="card">
                            <div class="card-body">
                                 <FacilitiesSidebar />
                              </div>
                            </div>
                          </div>                        
                        </div>
                      </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default EmergencyMedicare
